import { FC, useEffect, useState } from "react";
import { AlertDialog } from "@twilio-paste/core/alert-dialog";
import { Box } from "@twilio-paste/core/box";
import { Button } from "@twilio-paste/button";
import { Checkbox, CheckboxGroup } from "@twilio-paste/core/checkbox";
import { Host, ListSkillSet, Role, SkillSetItem } from "@ciptex/notified";
import { Input } from "@twilio-paste/core/input";
import { Label } from "@twilio-paste/core/label";
import { Schedule } from "@ciptex/schedule-sdk";
import { ScheduleExceptionMaker } from "../ScheduleExceptionMaker/ScheduleExceptionMaker";
import { SkeletonLoader } from "@twilio-paste/core/skeleton-loader";
import { Select, Option } from "@twilio-paste/core/select";
import { Separator } from "@twilio-paste/core/separator";
import { useNavigate, useParams } from "react-router-dom";
import { useNotifiedContext } from "../../hooks/useNotifiedContext/useNotifiedContext";
import { useScheduleContext } from "../../hooks/useScheduleContext/useScheduleContext";
import { useToasterContext } from "../../hooks/useToasterContext/useToasterContext";
import { TidyHostExceptions } from "../../functions/clean-schedule";

export const EditHost: FC = (props) => {
	const [checked, setChecked] = useState<string[]>([]);
	const { getHost, removeHost, resetPassword, listSkillSets, updateHost } = useNotifiedContext();
	const { hostId } = useParams();
	const [skillSet, setSkillSet] = useState<SkillSetItem>();
	const [loaded, setLoaded] = useState<boolean>(false);
	const [loading, setLoading] = useState<boolean>(false);
	const [submissionLoading, setSubmissionLoading] = useState<boolean>(false);
	const [host, setHost] = useState<Host>();
	const [schedules, setSchedules] = useState<Schedule[]>([]);
	const [schedule, setSchedule] = useState<any>();
	const [isOpen, setIsOpen] = useState<boolean>(false);
	const navigate = useNavigate();
	const { toaster } = useToasterContext();
	const handleOpen = () => setIsOpen(true);
	const handleClose = () => setIsOpen(false);
	const { listSchedule } = useScheduleContext();

	useEffect(() => {
		if (!hostId) {
			navigate("/hosts");
			return;
		}
		setLoading(true);
		(async () => {
			try {
				const data: Host = await getHost(hostId);

				if (data?.scheduleId === "") {
					delete data?.scheduleId;
				}

				setHost(data);
				if (data.skills) {
					setChecked(data.skills);
				}
				if (data.scheduleExceptions) {
					setSchedule(data.scheduleExceptions);
				}
				setLoading(false);
			} catch (error) {
				console.error(error);
				setLoading(false);
			}
		})();
	}, []);

	const handleSchedule = async (pageSize: number, scheduleId?: string) => {
		try {
			const data: Schedule[] = await listSchedule(pageSize, scheduleId);
			setSchedules(data);
			setLoaded(true);
		} catch (error) {
			console.error(error);
		}
	};

	useEffect(() => {
		handleSchedule(100, "");
	}, []);

	const onCheckboxChange = ({ target }: any) => {
		if (target.checked) {
			setChecked([...checked, target.value]);
		} else {
			setChecked(checked.filter((value: string) => value !== target.value));
		}
	};

	const onSelectChange = ({ target }: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
		setHost({
			...host,
			[target.name]: target.value
		} as Host);
	};

	const handleDelete = async () => {
		if (!hostId) {
			navigate("/hosts");
			return;
		}

		try {
			await removeHost(hostId);
			toaster.push({
				message: "Host has been removed",
				variant: "success",
				dismissAfter: 4000
			});
			navigate("/hosts");
		} catch (error) {
			console.error(error);
			toaster.push({
				message: "Host could not be removed",
				variant: "error",
				dismissAfter: 4000
			});
		}
	};

	const handleResetPassword = async () => {
		if (!hostId) {
			navigate("/hosts");
			return;
		}
		setLoading(true);
		try {
			await resetPassword(hostId);
			toaster.push({
				message: "Password rest link has been sent to the host",
				variant: "success",
				dismissAfter: 4000
			});
			setLoading(false);
			navigate("/hosts");
		} catch (error) {
			console.error(error);
			toaster.push({
				message: "Password could not be reset",
				variant: "error",
				dismissAfter: 4000
			});
			setLoading(false);
		}
	};

	const handleHostUpdate = async () => {
		if (!hostId || !host) {
			return;
		}
		TidyHostExceptions(schedule);
		host.scheduleExceptions = schedule;
		host.skills = checked;
		setSubmissionLoading(true);
		try {
			const data = await updateHost(hostId, host);
			setHost(data);
			toaster.push({
				message: "Host detail has been updated",
				variant: "success",
				dismissAfter: 4000
			});
			setSubmissionLoading(false);
		} catch (error) {
			console.error(error);
			toaster.push({
				message: "Host detail could not be updated",
				variant: "error",
				dismissAfter: 4000
			});
			setSubmissionLoading(false);
		}
	};

	useEffect(() => {
		(async () => {
			try {
				const data: ListSkillSet = await listSkillSets();
				if (data.skillsets.length === 1) {
					setSkillSet(data.skillsets[0]);
					setLoaded(true);
				}
			} catch (error) {
				console.error(error);
				toaster.push({
					message: "There has been an error in loading data",
					variant: "error",
					dismissAfter: 4000
				});
			}
		})();
	}, []);

	return (
		<>
			<Box
				marginY="space60"
				display="flex"
				justifyContent="space-between"
				flexDirection="row"
				columnGap="space60"
				alignItems="end"
			>
				{host && !loading ?
					<Box flexDirection="row" display="flex" columnGap="space60">
						<Box>
							<Label htmlFor="hostfname">First name</Label>
							<Input
								id="hostfname"
								name="hostfname"
								type="text"
								value={host?.firstName}
								readOnly
							/>
						</Box>
						<Box>
							<Label htmlFor="hostlname">Last name</Label>
							<Input
								id="hostlname"
								name="hostlname"
								type="text"
								value={host?.lastName}
								readOnly
							/>
						</Box>
						<Box>
							<Label htmlFor="email">Email</Label>
							<Input
								id="email"
								name="email"
								type="text"
								value={host?.email}
								readOnly
							/>
						</Box>
						<Box>
							<Label htmlFor="role" required>Role</Label>
							<Select id="role" required name="role" onChange={onSelectChange} value={host.role}>
								<Option value="Select a Role" defaultValue="Select a Role">Select a Role</Option>
								{Object.entries(Role).filter(([, value]) => value !== Role.NOTIFIED_SPEAKER && value !== Role.NOTIFIED_VIEWER).map(([key, value]) => (
									<Option key={key} value={value}>{key.replace("_", " ")}</Option>
								))}
							</Select>
						</Box>
					</Box>
					: <SkeletonLoader height="80px" width="500px" />
				}
				<Box display="flex" >
					<Button variant="secondary" onClick={handleResetPassword} loading={loading}>Password Reset</Button>
				</Box>
			</Box>

			<Separator orientation="horizontal" verticalSpacing="space120" />
			{host && !loading ?
				<Box marginY="space60" display="flex" justifyContent="start" flexDirection="row" columnGap="space60" alignItems="end">
					<Box>
						<Label htmlFor="schedule">Select Schedule</Label>
						<Select id="scheduleId" name="scheduleId" required onChange={onSelectChange} value={host?.scheduleId ? host?.scheduleId : ""}>
							<Option value="">Select a schedule</Option>
							{schedules.map((x) => (
								<Option key={x.scheduleId} value={x.scheduleId ? x.scheduleId : ""}>{x.name}</Option>
							))}
						</Select>
					</Box>
				</Box>
				: <SkeletonLoader height="100px" />
			}
			<Separator orientation="horizontal" verticalSpacing="space120" />
			{host && !loading ?
				<ScheduleExceptionMaker {...props} setScheduleHoliday={setSchedule} holiday={schedule} /> : <SkeletonLoader height="100px" />}
			<Separator orientation="horizontal" verticalSpacing="space120" />
			<Box display="flex"
				flexDirection="row"
				marginY="space60"
				flexWrap="wrap">
				{skillSet && loaded ? (
					<CheckboxGroup name="addskills" legend="Skills" helpText="Choose Host Skills">
						{skillSet.skills &&
							skillSet.skills.map((skill: { key?: string | undefined; }) => (
								<Checkbox
									key={skill.key}
									id={skill.key}
									checked={checked.includes(skill.key as any)}
									value={skill.key}
									name={skill.key}
									onClick={onCheckboxChange}>
									{skill.key || "error"}
								</Checkbox>
							))}
					</CheckboxGroup>
				) : (<SkeletonLoader width="100px" height="100px" />)}
			</Box>
			<Separator orientation="horizontal" verticalSpacing="space120" />
			<Box display="flex" justifyContent="space-between">
				<Button variant="destructive" onClick={handleOpen}>
					Delete
				</Button>
				<AlertDialog
					heading="Delete Host"
					isOpen={isOpen}
					destructive
					onConfirm={handleDelete}
					onConfirmLabel="Yes"
					onDismiss={handleClose}
					onDismissLabel="No">
					Are you sure you want to delete this host?
				</AlertDialog>
				<Button variant="primary" onClick={handleHostUpdate} loading={submissionLoading}>Save</Button>
			</Box>
		</>
	);
};