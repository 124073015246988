import { ConferenceItem, DialInGroup } from "@ciptex/notified";
import { DateTime } from "luxon";
import { AccountFieldName, BookingConferences, BookingFieldName, ConferenceFieldName, EventFieldName, OptionsFieldName } from "../types/ciptex-sdk";

const FormatColumn = (value?: string | number | boolean | DialInGroup[], format?: string[]) => {
	if (value && format?.includes("dateTime")) {
		return DateTime.fromISO(value.toString()).toLocaleString(DateTime.DATETIME_FULL);
	}
	else {
		return value;
	}
};

export const RenderColumn = (name: string, fieldPath: string, data: BookingConferences | ConferenceItem, format?: string[]): string | boolean | number | ("US" | "RestOfWorld" | "US-TollFree" | "RestOfWorld-TollFree")[] | undefined => {
	if (name && fieldPath.includes("booking")) {
		const booking = data as BookingConferences;
		if (fieldPath === "booking.event" && booking.event) {
			return FormatColumn(booking.event[name as EventFieldName], format);

		} else if (fieldPath === "booking.options" && booking.options) {
			return FormatColumn(booking.options[name as OptionsFieldName], format);

		} else if (fieldPath === "booking.account" && booking.account) {
			return FormatColumn(booking.account[name as AccountFieldName], format);

		} else if (fieldPath === "booking") {
			return FormatColumn(booking[name as BookingFieldName], format);
		}
	}
	else if (name && fieldPath.includes("conference")) {
		const conference = data as ConferenceItem;
		return FormatColumn(conference[name as ConferenceFieldName], format);
	}

	return;
};

