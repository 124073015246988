import { FC, useEffect, useState } from "react";
import { AlertDialog } from "@twilio-paste/core/alert-dialog";
import { Button } from "@twilio-paste/core/button";
import { Box } from "@twilio-paste/core/box";
import { Combobox } from "@twilio-paste/core/combobox";
import { Input } from "@twilio-paste/core/input";
import { Label } from "@twilio-paste/core/label";
import { Schedule } from "@ciptex/schedule-sdk";
import { ScheduleExceptionMaker } from "../ScheduleExceptionMaker/ScheduleExceptionMaker";
import { ScheduleMaker } from "../ScheduleMaker/ScheduleMaker";
import { SkeletonLoader } from "@twilio-paste/core/skeleton-loader";
import { Separator } from "@twilio-paste/core/separator";
import { useNavigate, useParams } from "react-router-dom";
import { useScheduleContext } from "../../hooks/useScheduleContext/useScheduleContext";
import { useToasterContext } from "../../hooks/useToasterContext/useToasterContext";
import { TIMEZONES } from "../../constants";
import { TidyScheduleExceptions, TidyScheduleWeekly } from "../../functions/clean-schedule";

export const EditSchedule: FC = (props) => {
	const { scheduleId } = useParams();
	const { getSchedule, updateSchedule } = useScheduleContext();
	const [isOpen, setIsOpen] = useState(false);
	const [submissionLoading, setSubmissionLoading] = useState(false);
	const [loading, setLoading] = useState(false);
	const [schedule, setSchedule] = useState<Schedule>();
	const [holiday, setHoliday] = useState<any>();
	const [weekly, setWeekly] = useState<any>();
	const { toaster } = useToasterContext();
	const { removeSchedule } = useScheduleContext();
	const [timezone, setTimezone] = useState(TIMEZONES);
	const handleOpen = () => setIsOpen(true);
	const handleClose = () => setIsOpen(false);
	const navigate = useNavigate();

	useEffect(() => {
		if (!scheduleId) {
			navigate("/schedules");
			return;
		}
		setLoading(true);
		(async () => {
			try {
				const data: Schedule = await getSchedule(scheduleId);
				setSchedule(data);
				setLoading(false);
				if(data.weekly){
					setWeekly(data.weekly);
				}
				if(data.holiday){
					setHoliday(data.holiday);
				}
			} catch (error) {
				console.error(error);
				setLoading(false);
			}
		})();
	}, []);

	const handleNameChange = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
		setSchedule({
			...schedule,
			[target.name]: target.value
		} as Schedule);
	};

	const handleDelete = async () => {
		if (!scheduleId) {
			navigate("/schedules");
			return;
		}

		try {
			await removeSchedule(scheduleId);
			toaster.push({
				message: "Schedule has been removed",
				variant: "success",
				dismissAfter: 4000
			});
			navigate("/schedules");
		} catch (error) {
			console.error(error);
			toaster.push({
				message: "Schedule could not be removed",
				variant: "error",
				dismissAfter: 4000
			});
		}
	};

	const handleScheduleUpdate = async () => {
		if (!scheduleId || !schedule) {
			return;
		}
		TidyScheduleExceptions(holiday);
		TidyScheduleWeekly(weekly);
		schedule.holiday = holiday;
		schedule.weekly = weekly;
		setSubmissionLoading(true);
		try {
			const data = await updateSchedule(scheduleId, schedule);
			setSchedule(data);
			toaster.push({
				message: "Schedule detail has been updated",
				variant: "success",
				dismissAfter: 4000
			});
			setSubmissionLoading(false);
		} catch (error) {
			console.error(error);
			toaster.push({
				message: "Schedule detail could not be updated",
				variant: "error",
				dismissAfter: 4000
			});
			setSubmissionLoading(false);
		}
	};

	return (
		<>
			<Box
				marginY="space60"
				display="flex"
				justifyContent="start"
				flexDirection="row"
				columnGap="space60"
				alignItems="start"
			>
				{schedule && !loading ?
					<>
						<Box>
							<Label htmlFor="name">Schedule Name</Label>
							<Input
								id="name"
								name="name"
								type="text"
								defaultValue={schedule?.name}
								onChange={handleNameChange}
							/>
						</Box>
						<Box>
							<Combobox
								autocomplete
								items={timezone}
								labelText="Schedule Timezones"
								helpText={`Current set TZ: ${schedule?.timezone}`}
								onInputValueChange={({ inputValue }) => {
									if (inputValue !== undefined) {
										const tz: string[] = TIMEZONES.filter(item => item.toLowerCase().startsWith(inputValue.toLowerCase()));
										if ( tz.length === 1) {
											setSchedule({
												...schedule,
												timezone: tz.toString()
											} as Schedule);
										}
										setTimezone(TIMEZONES.filter(item => item.toLowerCase().startsWith(inputValue.toLowerCase())));
									}
								}}
							/>
						</Box>
					</>
					: <SkeletonLoader height="80px" width="500px" />}
			</Box>
			<Separator orientation="horizontal" verticalSpacing="space120" />
			{schedule && !loading ?
				<ScheduleMaker {...props} setScheduleWeekly={setWeekly} weekly={weekly} /> : <SkeletonLoader height="100px" />}
			<Separator orientation="horizontal" verticalSpacing="space120" />
			{schedule && !loading ?

				<ScheduleExceptionMaker {...props} setScheduleHoliday={setHoliday} holiday={holiday} /> : <SkeletonLoader height="100px" />}
			<Separator orientation="horizontal" verticalSpacing="space120" />
			<Box
				marginY="space60"
				display="flex"
				justifyContent="space-between"
				flexDirection="row"
				columnGap="space60"
				alignItems="end"
			>
				<Button variant="destructive" onClick={handleOpen}>Delete</Button>
				<AlertDialog
					heading="Delete Schedule"
					isOpen={isOpen}
					destructive
					onConfirm={handleDelete}
					onConfirmLabel="Yes"
					onDismiss={handleClose}
					onDismissLabel="No">
					Are you sure you want to delete this schedule?
				</AlertDialog>
				<Button variant="primary" loading={submissionLoading} onClick={handleScheduleUpdate}>Save</Button>
			</Box>
		</>
	);
};