import { BookingItem, BookingStatus, ConferenceItem, HostItem, ListBooking, ListConference } from "@ciptex/notified";
import { createContext, FC, useCallback, useEffect, useState } from "react";
import { PAGE_SIZE } from "../../constants";
import { DownloadXLSX } from "../../functions/download-xlsx";
import { getSearchTypeFromQuery, SearchType } from "../../functions/get-search-type-from-query";
import { useAppState } from "../../hooks/useAppState/useAppState";
import { useNotifiedContext } from "../../hooks/useNotifiedContext/useNotifiedContext";
import { ReactElementProps } from "../../interface";
import { AdminContextType, BookingConferences } from "../../types/ciptex-sdk";

export const AdminContext = createContext<AdminContextType>(null!);

export const AdminProvider: FC<ReactElementProps> = ({ children }: ReactElementProps) => {
	const { appState } = useAppState();
	const { providerReady, listBooking, listConference, listHost, getBooking } = useNotifiedContext();
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [conferences, setConferences] = useState<ConferenceItem[]>([]);
	const [bookings, setBookings] = useState<BookingConferences[]>([]);
	const [hosts, setHosts] = useState<HostItem[]>([]);

	useEffect(() => {
		(async () => {
			const list = await listHost(300);
			setHosts(list.hosts);
		})()
	}, [ providerReady ]);

	// Convert a local datetime string (as output from datetime-local input) to a UTC date string.
	// e.g. "2022-01-29T22:00" (in CET) to "2022-01-29T21:00:00.000Z" (in UTC)
	const localTimestampToUTC = (dateString: string) => {
		const localDate = new Date(dateString);

		return localDate.toJSON();
	};

	const appendConferences = async (bookings: BookingItem[], resultLength: number): Promise<BookingConferences[]> => {
		if (resultLength > PAGE_SIZE) {
			return [];
		}

		const result: BookingConferences[] = [];
		const promiseArray = [];

		for (const booking of bookings) {
			try {
				const conferenceList = listConference(booking.bookingId, PAGE_SIZE, undefined, {
					startDateFrom: appState.filters.startDateFrom !== "" ? localTimestampToUTC(appState.filters.startDateFrom) : undefined,
					startDateTo: appState.filters.startDateTo !== "" ? localTimestampToUTC(appState.filters.startDateTo) : undefined,
					conferenceType: appState.filters.conferenceType !== "" ? appState.filters.conferenceType : undefined,
					hostId: appState.filters.hostId !== "" ? appState.filters.hostId : undefined
				});
				promiseArray.push(conferenceList);
			} catch (error: any) {
				console.error(error);
			}
		}

		const promises = await Promise.allSettled(promiseArray);

		promises.filter(x => x.status === "fulfilled").forEach((promiseResult) => {
			if (promiseResult.status === "fulfilled") {
				if (promiseResult.value.conferences.length > 0) {
					const booking = bookings.find(x => x.bookingId === promiseResult.value.conferences[0].bookingId);

					if (booking) {
						result.push({ ...booking, conferences: promiseResult.value.conferences });
					}
				}
			}
		});

		return result;
	};

	const appendBookings = async (conferences: ConferenceItem[], resultLength: number): Promise<BookingConferences[]> => {
		if (resultLength > PAGE_SIZE) {
			return [];
		}

		const result: BookingConferences[] = [];
		const promiseArray = [];

		for (const conference of conferences) {
			try {
				const booking = getBooking(conference.bookingId);
				promiseArray.push(booking);
			} catch (error: any) {
				console.error(error);
			}
		}

		const promises = await Promise.allSettled(promiseArray);

		promises.filter(x => x.status === "fulfilled").forEach((promiseResult) => {
			if (promiseResult.status === "fulfilled") {
				if (promiseResult.value) {
					const booking =  promiseResult.value;
					const conference = conferences.find(x => x.bookingId === booking.bookingId);

					if (conference) {
						result.push({ ...booking, conferences: [conference] });
					}
				}
			}
		});

		return result;
	};

	const loadBookings = useCallback(async (lastKey?: string, pageSize?: number): Promise<void> => {
		setIsLoading(true);

		let result: BookingConferences[] = [];
		let InternalLastKey: string | undefined = lastKey;

		while (result.length < (pageSize || PAGE_SIZE)) {
			console.log("MD", `Result is Currently: ${result.length}`);
			try {
				const bookingslocal: ListBooking = await listBooking(((pageSize || PAGE_SIZE) - result.length), InternalLastKey, {
					hostId: appState.filters.hostId !== "" ? appState.filters.hostId : undefined,
					search: appState.filters.search !== "" ? appState.filters.search : undefined,
					status: appState.filters.status !== "" ? appState.filters.status : undefined,
					region: appState.filters.region !== "" ? appState.filters.region : undefined,
					startDateFrom: appState.filters.startDateFrom !== "" ? localTimestampToUTC(appState.filters.startDateFrom) : undefined,
					startDateTo: appState.filters.startDateTo !== "" ? localTimestampToUTC(appState.filters.startDateTo) : undefined
				});
				console.log("MD", `${bookingslocal.bookings.length} Bookings Downloaded`);
				const data = await appendConferences(bookingslocal.bookings, result.length);
				result = [...result, ...data];

				if (bookingslocal.bookings.length === ((pageSize || PAGE_SIZE) - (result.length - data.length) )) {
					console.log("MD", `Last Booking Id is ${bookingslocal.bookings[bookingslocal.bookings.length - 1].bookingId}. There May be More Records`);
					InternalLastKey = bookingslocal.bookings[bookingslocal.bookings.length - 1].bookingId;
				}
				else {
					console.log("MD", "Not a full page returned so assume no more records");
					break;
				}
			} catch (error: any) {
				console.error(error);
				setIsLoading(false);
				break;
			}
		}

		console.log("MD", `After Filtering we have ${result.length} Results`);

		setBookings([...result]);
		setIsLoading(false);
	}, [appState.filters, bookings, providerReady]);

	const loadConferences = useCallback(async (lastKey?: string, pageSize?: number): Promise<void> => {
		setIsLoading(true);

		let result: BookingConferences[] = [];
		let InternalLastKey: string | undefined = lastKey;

		while (result.length < (pageSize || PAGE_SIZE)) {
			console.log("MD", `Result is Currently: ${result.length}`);

			try {
				const bookingId = undefined;
				const conferencesLocal: ListConference = await listConference(bookingId, ((pageSize || PAGE_SIZE) - result.length), InternalLastKey, {
					hostId: appState.filters.hostId !== "" ? appState.filters.hostId : undefined,
					search: appState.filters.search !== "" ? appState.filters.search : undefined,
					status: appState.filters.status !== "" ? appState.filters.status : undefined,
					startDateFrom: appState.filters.startDateFrom !== "" ? localTimestampToUTC(appState.filters.startDateFrom) : undefined,
					startDateTo: appState.filters.startDateTo !== "" ? localTimestampToUTC(appState.filters.startDateTo) : undefined
				});
				console.log("MD", `${conferencesLocal.conferences.length} Conferences Downloaded`);

				const data = await appendBookings(conferencesLocal.conferences, result.length);
				result = [...result, ...data];

				if (conferencesLocal.conferences.length === ((pageSize || PAGE_SIZE) - (result.length - data.length) )) {
					console.log("MD", `Last Conference Id is ${conferencesLocal.conferences[conferencesLocal.conferences.length - 1].conferenceId}. There May be More Records`);
					InternalLastKey = conferencesLocal.conferences[conferencesLocal.conferences.length - 1].conferenceId;
				}
				else {
					console.log("MD", "Not a full page returned so assume no more records");
					break;
				}
			} catch (error: any) {
				console.error(error);
				setIsLoading(false);
				break;
			}
		}

		console.log("MD", `After Filtering we have ${result.length} Results`);

		setBookings([...result]);
		setIsLoading(false);
	}, [appState.filters, bookings, providerReady]);

	const getBookings = async (lastKey?: string, pageSize?: number): Promise<void> => {
		/**
		 * When fetching bookings we normally have to find all bookings matching the given filters,
		 * and then for each booking request the conferences for that booking.
		 *
		 * However, if we're searching for a conference we have to instead search for the conference
		 * first and then find the attached booking. Otherwise, if we search for bookings first and then
		 * try to find a matching conference within those bookings:
		 *
		 * a) That's extremely ineffecient
		 * b) We'll return bookings we need to discard if there are no matching conferences for them
		 *    (which complicated paging)
		 * c) For bookings on a given page there may not be a matching conference, but there may be
		 *    on a later page, which means we can't rely on paging through bookings to find a conference.
		 *    (see [a] and [b]).
		 */

		const searchTerm = appState.filters.search;
		const searchType = getSearchTypeFromQuery(searchTerm);

		switch (searchType) {
		case SearchType.ConferenceId:
			await loadConferences(lastKey, pageSize);
			break;

		default:
			await loadBookings(lastKey, pageSize);
		}
	};

	const getAllBookingsMatchingFilters = async (): Promise<BookingItem[]> => {
		const allBookings: BookingItem[] = [];

		// If no status filter is applied, the API will default to showing all statuses _except_ for completed bookings.
		// This is fine for the results displayed in the table in the web interface, but for the CSV export we want to include all bookings (including completed).
		// So, if a booking status filter is specified we'll respect that when downloading records, but if one isn't specified we need to force it to include all statuses,
		// otherwise we'll miss out completed bookings.
		const statuses = appState.filters.status
			? [appState.filters.status]
			: [BookingStatus.ACCEPTED, BookingStatus.ASSIGNED, BookingStatus.CANCELED, BookingStatus.PENDING, BookingStatus.INACTIVE, BookingStatus.COMPLETED];

		for (const status of statuses) {
			let hasMoreBookings = true;
			let lastKey;

			while (hasMoreBookings) {
				const listBookingsResponse: ListBooking = await listBooking(PAGE_SIZE, lastKey, {
					hostId: appState.filters.hostId !== "" ? appState.filters.hostId : undefined,
					search: appState.filters.search !== "" ? appState.filters.search : undefined,
					status,
					region: appState.filters.region !== "" ? appState.filters.region : undefined,
					startDateFrom: appState.filters.startDateFrom !== "" ? localTimestampToUTC(appState.filters.startDateFrom) : undefined,
					startDateTo: appState.filters.startDateTo !== "" ? localTimestampToUTC(appState.filters.startDateTo) : undefined
				});

				if (listBookingsResponse.bookings?.length) {
					allBookings.push(...listBookingsResponse.bookings);
				}

				lastKey = listBookingsResponse?.meta?.lastKey;
				hasMoreBookings = listBookingsResponse.bookings.length === PAGE_SIZE;
			}
		}

		return allBookings;
	};

	const getAllConferencesMatchingFilters = async (): Promise<ConferenceItem[]> => {
		const allConferences: ConferenceItem[] = [];

		// If no status filter is applied, the API will default to showing all statuses _except_ for completed bookings.
		// This is fine for the results displayed in the table in the web interface, but for the CSV export we want to include all bookings (including completed).
		// So, if a booking status filter is specified we'll respect that when downloading records, but if one isn't specified we need to force it to include all statuses,
		// otherwise we'll miss out completed bookings.
		const statuses = appState.filters.status
			? [appState.filters.status]
			: [BookingStatus.ACCEPTED, BookingStatus.ASSIGNED, BookingStatus.CANCELED, BookingStatus.PENDING, BookingStatus.INACTIVE, BookingStatus.COMPLETED];

		for (const status of statuses) {
			let hasMoreConferences = true;
			let lastKey;

			while (hasMoreConferences) {
				const bookingId = undefined;
				const listConferencesResponse: ListConference = await listConference(bookingId, PAGE_SIZE, lastKey, {
					hostId: appState.filters.hostId !== "" ? appState.filters.hostId : undefined,
					search: appState.filters.search !== "" ? appState.filters.search : undefined,
					status,
					startDateFrom: appState.filters.startDateFrom !== "" ? localTimestampToUTC(appState.filters.startDateFrom) : undefined,
					startDateTo: appState.filters.startDateTo !== "" ? localTimestampToUTC(appState.filters.startDateTo) : undefined
				});

				if (listConferencesResponse.conferences?.length) {
					allConferences.push(...listConferencesResponse.conferences);
				}

				lastKey = listConferencesResponse?.meta?.lastKey;
				hasMoreConferences = listConferencesResponse.conferences.length === PAGE_SIZE;
			}
		}

		return allConferences;
	};

	const downloadBookingsAsXlsx = useCallback(async (): Promise<void> => {
		const getBookingsAndConferences = async () => {
			const searchType = getSearchTypeFromQuery(appState.filters.search);

			switch (searchType) {
			case SearchType.ConferenceId:
				try {
					const conferenceItems = await getAllConferencesMatchingFilters();
					const maxResults = -1;

					return await appendBookings(conferenceItems, maxResults);
				} catch (error) {
					console.error("Failed downloading bookings and conferences", error);
					return [];
				}

			default:
				try {
					const bookingItems = await getAllBookingsMatchingFilters();
					const maxResults = -1;

					return await appendConferences(bookingItems, maxResults);
				} catch (error) {
					console.error("Failed downloading bookings and conferences", error);
					return [];
				}
			}
		};

		setIsLoading(true);

		await DownloadXLSX(
			await getBookingsAndConferences(),
			hosts
		);

		setIsLoading(false);
	}, [appState.filters, bookings, providerReady]);

	const updateBooking = useCallback((booking: BookingConferences) => {
		const tempBookings = [...bookings];
		const index = bookings.findIndex(x => x.bookingId === booking.bookingId);
		tempBookings[index] = booking;
		setBookings(tempBookings);
	}, [ bookings, providerReady ]);

	const getConferences = useCallback(async (): Promise<void> => {
		setIsLoading(true);
		try {
			const conferencelocal: ListConference = await listConference(undefined, 5000, undefined, {
				startDateFrom: appState.filters.startDateFrom !== "" ? appState.filters.startDateFrom : undefined,
				startDateTo: appState.filters.startDateTo !== "" ? appState.filters.startDateTo : undefined,
				hostId: appState.filters.hostId !== "" ? appState.filters.hostId : undefined,
				conferenceType: appState.filters.conferenceType !== "" ? appState.filters.conferenceType : undefined
			});

			setConferences(conferencelocal.conferences);
			setIsLoading(false);
		} catch (error) {
			console.error(error);
			setIsLoading(false);
		}
	}, [ appState.filters, conferences, providerReady ]);

	return <AdminContext.Provider value={{ getBookings, updateBooking, getConferences, downloadBookingsAsXlsx, bookings, conferences, hosts, isLoading }}>{children}</AdminContext.Provider>;
}