import { BookingStatus, ConferenceType, Region, Role } from "@ciptex/notified";
import { produce } from "immer";
import { GetBookingColumns } from "../functions/get-booking-columns";
import { BookingColumn } from "../hooks/useBookingColumns/useBookingColumns";

interface Filters {
	search: string;
	hostId: string;
	conferenceType: ConferenceType | "";
	region: Region | "";
	status: BookingStatus | "";
	startDateFrom: string;
	startDateTo: string;
	dateTime: string;
}

export interface appStateTypes {
	accountSid: string;
	hostId: string
	token: string;
	role: Role;
	isLoading: boolean;
	filters: Filters
	isNavOpen: boolean,
	columns: BookingColumn[]
}

export type appActionTypes =
	| { type: "set-account-sid"; accountSid: string }
	| { type: "set-host-id"; hostId: string }
	| { type: "set-token"; token: string }
	| { type: "set-is-loading"; isLoading: boolean }
	| { type: "set-role"; role: Role }
	| { type: "reset-state" }
	| { type: "set-filters", filters: Filters }
	| { type: "set-booking-columns", columns: BookingColumn[] }
	| { type: "set-is-nav-open", isNavOpen: boolean }

export const initialAppState: appStateTypes = {
	accountSid: "",
	hostId: "",
	token: "",
	isLoading: false,
	role: Role.NOTIFIED_HOST,
	filters: { search: "", hostId: "", conferenceType: "", region: "", status: "", dateTime: "", startDateFrom: "", startDateTo: "" },
	isNavOpen: true,
	columns: GetBookingColumns()
};

export const appReducer = produce((draft: appStateTypes, action: appActionTypes) => {
	switch (action.type) {
	case "set-account-sid":
		draft.accountSid = action.accountSid;
		break;
	case "set-host-id":
		draft.hostId = action.hostId;
		break;
	case "set-token":
		draft.token = action.token;
		break;
	case "set-is-loading":
		draft.isLoading = action.isLoading;
		break;
	case "set-role":
		draft.role = action.role;
		break;
	case "set-filters":
		draft.filters = action.filters;
		break;
	case "set-booking-columns":
		draft.columns = action.columns;
		break;
	case "set-is-nav-open":
		draft.isNavOpen = action.isNavOpen;
		break;
	case "reset-state":
		return initialAppState;
	default:
		console.error("Invalid Reducer");
		break;
	}
});