import { useContext } from "react";
import { ScheduleContext } from "../../components/ScheduleProvider/ScheduleProvider";
import { ScheduleContextType } from "../../types/ciptex-sdk";

export const useScheduleContext = (): ScheduleContextType => {
	const context = useContext(ScheduleContext);
	if (!context) {
		throw new Error("useScheduleContext must be used within a ScheduleProvider");
	}
	return context;
}