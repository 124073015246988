import { FC, useState, useMemo, useRef, useEffect } from "react";
import { Box } from "@twilio-paste/core/box";
import { Eventcalendar, MbscCalendarEvent, MbscEventClickEvent, MbscPageChangeEvent } from "@mobiscroll/react";
import { ConferenceItem } from "@ciptex/notified";
import { getRandomColor } from "../../functions/get-random-color";
import { Filter } from "../Filters/Filters";
import { useAppState } from "../../hooks/useAppState/useAppState";
import { useAdminContext } from "../../hooks/useAdminContext/useAdminContext";

const conferenceMap = (conferences: ConferenceItem[]): MbscCalendarEvent[] => {
	return conferences.map(({ conferenceId, startDate, endDate, conferenceName }: ConferenceItem) => ({
		id: conferenceId,
		start: startDate,
		end: endDate,
		title: conferenceName,
		color: getRandomColor(),
		editable: false
	}));
};

export const Calendar: FC = () => {
	const { appState, appDispatch } = useAppState();
	const { conferences, getConferences } = useAdminContext();
	const [conferencesState, setConferencesState] = useState<MbscCalendarEvent[]>();
	const calendar = useRef<Eventcalendar>(null);

	useEffect(() => {
		console.log("conferences updated");
		const dataMap = conferenceMap(conferences);
		setConferencesState([...dataMap]);
	}, [conferences]);

	useEffect(() => {
		getConferences();
	}, [
		appState.filters
	])

	const onPageChange = async (event: MbscPageChangeEvent) => {
		appDispatch({
			type: "set-filters",
			filters: {
				...appState.filters,
				dateTime: "custom",
				startDateFrom: event.firstDay.toISOString(),
				startDateTo: event.lastDay.toISOString()
			}
		});
	};

	const onInit = async ({ inst }: any) => {
		appDispatch({
			type: "set-filters",
			filters: {
				...appState.filters,
				dateTime: "custom",
				startDateFrom: inst._firstDay.toISOString(),
				startDateTo: inst._lastDay.toISOString()
			}
		});
	};

	const onEventClick = async ({ event }: MbscEventClickEvent) => {
		console.log(event);
	};

	const view = useMemo(() => {
		return {
			calendar: { labels: true }
		};
	}, []);

	return (
		<>
			<Filter bookingFilters={false} dateFilters={false} showApply={false} />
			<Box height="79%">
				<Eventcalendar
					theme="ios"
					themeVariant="light"
					clickToCreate={false}
					dragToCreate={false}
					dragToMove={false}
					dragToResize={false}
					data={conferencesState}
					view={view}
					ref={calendar}
					onInit={onInit}
					onEventClick={onEventClick}
					onPageChange={onPageChange}
				/>
			</Box>
		</>
	);
};
