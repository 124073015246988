import { createContext, FC, useCallback, useState } from "react";
import { ReactElementProps } from "../../interface";
import { BookingFilters, ConferenceFilters, NotifiedContextType } from "../../types/ciptex-sdk";
import { Booking, BookingDetail, Host, HostDetail, ListBooking, ListConference, ListHost, ListSkillSet, NotifiedClient, SkillSet, SkillSetDetail } from "@ciptex/notified";

export const NotifiedContext = createContext<NotifiedContextType>(null!);

export const NotifiedProvider: FC<ReactElementProps> = ({ children }: ReactElementProps) => {
	const [notifiedClient, setNotifiedClient] = useState<NotifiedClient>();
	const [providerReady, setProviderReady] = useState<boolean>(false);

	const connect = useCallback((token: string) => {
		try {
			const client = new NotifiedClient({ token });
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			window.notifiedClient = client;
			setNotifiedClient(client);
			setProviderReady(true);
		} catch (error: any) {
			console.error(error);
		}
	}, []);

	const listBooking = useCallback(async (pageSize?: number, lastKey?: string, { hostId, status, region, search, startDateFrom, startDateTo }: BookingFilters = {}): Promise<ListBooking> => {
		const bookings = await notifiedClient?.booking.list(startDateFrom, startDateTo, hostId, status, region, search, pageSize, lastKey);
		if (bookings) {
			return bookings;
		}
		else {
			throw new Error("No Bookings");
		}
	}, [notifiedClient]);

	const listConference = useCallback(async (bookingId?: string, pageSize?: number, lastKey?: string, { startDateFrom, startDateTo, hostId, status, conferenceType, search }: ConferenceFilters = {}): Promise<ListConference> => {
		if (bookingId) {
			const conferences = await notifiedClient?.conference.list(bookingId, startDateFrom, startDateTo, hostId, status, conferenceType, search, pageSize, lastKey);
			if (conferences) {
				return conferences;
			}
			else {
				throw new Error("No Conferences");
			}
		}
		else {
			const conferences = await notifiedClient?.conference.searchConference(startDateFrom, startDateTo, hostId, status, conferenceType, search, pageSize, lastKey);
			if (conferences) {
				return conferences;
			}
			else {
				throw new Error("No Conferences");
			}
		}

	}, [notifiedClient]);

	const getHost = useCallback(async (hostId: string): Promise<Host> => {
		const host = await notifiedClient?.host.fetch(hostId);
		if (host) {
			return host;
		}
		else {
			throw new Error("No Host");
		}
	}, [notifiedClient]);

	const getBooking = useCallback(async (bookingId: string): Promise<Booking> => {
		const booking = await notifiedClient?.booking.fetch(bookingId);
		if (booking) {
			return booking;
		}
		else {
			throw new Error("No Booking");
		}
	}, [notifiedClient]);

	const listHost = useCallback(async (pageSize?: number, lastKey?: string): Promise<ListHost> => {
		const hosts = await notifiedClient?.host.list(pageSize, lastKey);
		if (hosts) {
			return hosts;
		}
		else {
			throw new Error("No Hosts");
		}
	}, [notifiedClient]);

	const listSkillSets = useCallback(async (pageSize?: number, lastKey?: string): Promise<ListSkillSet> => {
		const skillsets = await notifiedClient?.skillset.list(pageSize, lastKey);
		if (skillsets) {
			return skillsets;
		}
		else {
			throw new Error("No Skill Sets");
		}
	}, [notifiedClient]);

	const getSkill = useCallback(async (skillSetId: string): Promise<SkillSet> => {
		const skill = await notifiedClient?.skillset.fetch(skillSetId);
		if (skill) {
			return skill;
		}
		else {
			throw new Error("No Skills");
		}
	}, [notifiedClient]);

	const resetPassword = async (hostId: string): Promise<void> => {
		await notifiedClient?.hostauth.resetPassword(hostId);
	}

	const removeHost = async (hostId: string): Promise<void> => {
		await notifiedClient?.host.remove(hostId);
	}

	const createHost = async (hostObject: HostDetail): Promise<Host> => {
		const host = await notifiedClient?.host.create(hostObject);
		if (host) {
			return host;
		}
		else {
			throw new Error("No Host Created");
		}
	};

	const updateBooking = async (bookingId: string, requestBody: BookingDetail): Promise<Booking> => {
		const booking = await notifiedClient?.booking.update(bookingId, requestBody);
		if (booking) {
			return booking;
		}
		else {
			throw new Error("No Booking Updated");
		}
	};

	const updateSkill = async (skillSetId: string, requestBody: SkillSetDetail): Promise<SkillSet> => {
		const skills = await notifiedClient?.skillset.update(skillSetId, requestBody);
		if (skills) {
			return skills;
		}
		else {
			throw new Error("No Skill Updated");
		}
	};

	const updateHost = async (hostId: string, requestBody: HostDetail): Promise<Host> => {
		const host = await notifiedClient?.host.update(hostId, requestBody);
		if (host) {
			return host;
		}
		else {
			throw new Error("No Skill Updated");
		}
	};

	return <NotifiedContext.Provider value={{ providerReady, connect, listBooking, listConference, getHost, getBooking, listHost, listSkillSets, getSkill, resetPassword, removeHost, createHost, updateBooking, updateSkill, updateHost }}>{children}</NotifiedContext.Provider>;
}