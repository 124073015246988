import { FC, useEffect, useState } from "react";
import { Table, TBody, Th, THead, Tr, Td } from "@twilio-paste/core/table";
import { Box } from "@twilio-paste/core/box";
import { Button } from "@twilio-paste/core/button";
import { useNavigate } from "react-router-dom";
import { useScheduleContext } from "../../hooks/useScheduleContext/useScheduleContext";
import { Pagination, PaginationItems, PaginationArrow, PaginationLabel } from "@twilio-paste/core/pagination";
import { Schedule } from "@ciptex/schedule-sdk";
import { TableSkeletonLoader } from "../TableSkeletonLoader/TableSkeletonLoader";
import { ChevronDownIcon } from "@twilio-paste/icons/esm/ChevronDownIcon";
import { ChevronUpIcon } from "@twilio-paste/icons/esm/ChevronUpIcon";
import { UnsortedIcon } from "@twilio-paste/icons/esm/UnsortedIcon";
import { Flex } from "@twilio-paste/flex";
import { PAGE_SIZE } from "../../constants";

export const ScheduleList: FC = () => {
	const [schedules, setSchedules] = useState<Schedule[]>([]);
	const [loaded, setLoaded] = useState<boolean>(false);
	const { listSchedule } = useScheduleContext();
	const [currentPage, setCurrentPage] = useState(1);
	const [firstScheduleID, setFirstScheduleID] = useState<string>();
	const navigate = useNavigate();
	const [toggleState, setToggleState] = useState<string>("none");

	const handleSchedule = async (pageSize: number, scheduleId?: string) => {
		try {
			const data: Schedule[] = await listSchedule(pageSize, scheduleId);
			setSchedules(data);
			setLoaded(true);
		} catch (error) {
			console.error(error);
		}
	};

	const sortData = (data: Schedule[], sortBy: string, sortOrder: string) => {
		const sortedItems = data.sort((a: any, b: any) => {
			if (sortBy === "name") {
				if (sortOrder === "asc") {
					setToggleState("asc");
					return b.name.localeCompare(a.name);
				} else {
					setToggleState("desc");
					return a.name.localeCompare(b.name);
				}
			}
		});
		return setSchedules(() => [...sortedItems]);
	}

	useEffect(() => {
		handleSchedule(PAGE_SIZE, "");
	}, []);

	const goToNextPage = (event: any) => {
		setCurrentPage((page) => Math.min(page + 1));
		const lastScheduleId = schedules[schedules.length - 1].scheduleId;
		if (currentPage === 1) {
			setFirstScheduleID("");
		}
		else {
			setFirstScheduleID(schedules && schedules[0].scheduleId);
		}
		handleSchedule(PAGE_SIZE, lastScheduleId);
		event.preventDefault();
	};

	const goToPreviousPage = (event: any) => {
		setCurrentPage((page) => Math.max(page - 1, 1));
		if (currentPage === 2) {
			handleSchedule(PAGE_SIZE, "");
		} else {
			handleSchedule(PAGE_SIZE, firstScheduleID);
		}
		event.preventDefault();
	};


	return (
		<>
			<Box
				marginY="space60"
				display="flex"
				justifyContent="end"
				flexDirection="row"
				columnGap="space60"
				alignItems="end">

				<Box minWidth="100px">
					<Button onClick={() => { navigate("/schedules/add") }} fullWidth variant="primary">Add a New Schedule</Button>
				</Box>
			</Box>
			<Table>
				<THead>
					<Tr>
						<Th><Flex vAlignContent="center">
								List of Schedules
							<Box marginLeft="space30" display="flex" alignItems="center">
								{toggleState === "desc" ? (
									<Button
										variant="secondary_icon"
										size="reset"
										onClick={() => sortData(schedules, "name", "asc")}
									>
										<ChevronUpIcon
											decorative={false}
											size="sizeIcon10"
											title="Ascending"
										/>
									</Button>
								) : (
									<Button
										variant="secondary_icon"
										size="reset"
										onClick={() => sortData(schedules, "name", "desc")}
									>
										{toggleState === "none" ? (
											<UnsortedIcon
												decorative={false}
												size="sizeIcon10"
												title="Ascending"
											/> ) : (
											<ChevronDownIcon
												decorative={false}
												size="sizeIcon10"
												title="Descending"
											/>
										)}
									</Button>
								)}
							</Box>
						</Flex></Th>
						<Th textAlign="right">Actions</Th>
					</Tr>
				</THead>
				<TBody>
					{schedules && loaded ? (
						schedules?.map((schedule: Schedule) => (
							<Tr key={schedule.scheduleId}>
								<Td>{schedule.name}</Td>
								<Td textAlign="right">
									<Box display="flex" flexDirection="row" justifyContent="flex-end" columnGap="space60">
										<Button variant="primary" onClick={() => { navigate(`/schedules/edit/${schedule.scheduleId}`); }}>Edit</Button>
									</Box>
								</Td>
							</Tr>
						))) : <TableSkeletonLoader numberOfTd={2} numberOfTr={1} />}
				</TBody>
			</Table>
			{schedules && loaded ? (
				<Box display="flex" justifyContent="center" marginTop="space70">
					<Pagination label="default pagination navigation">
						<PaginationItems>
							<PaginationArrow
								label="Go to previous page"
								variant="back"
								onClick={goToPreviousPage}
								disabled={currentPage === 1}
							/>
							<PaginationLabel>Page {currentPage}</PaginationLabel>
							<PaginationArrow
								label="Go to next page"
								variant="forward"
								onClick={goToNextPage}
								disabled={schedules.length < PAGE_SIZE}
							/>
						</PaginationItems>
					</Pagination>
				</Box>
			) : <></>}

		</>
	);
};
