import { FC, useEffect, useState } from "react";
import { Table, TBody, Th, THead, Tr, Td } from "@twilio-paste/core/table";
import { Box } from "@twilio-paste/core/box";
import { Label } from "@twilio-paste/core/label";
import { Button } from "@twilio-paste/core/button";
import { DeleteIcon } from "@twilio-paste/icons/esm/DeleteIcon";
import { Holiday, Message } from "@ciptex/schedule-sdk";
import { DateTime } from "luxon";
import { HelpText } from "@twilio-paste/core";

interface HolidayWithId extends Holiday {
	id: number
}

interface ScheduleExceptionMakerTable {
	holiday?: any,
	setScheduleHoliday?: any
}

export const ScheduleExceptionMaker: FC<ScheduleExceptionMakerTable> = ({ setScheduleHoliday, holiday }) => {
	const [row, setRow] = useState([] as HolidayWithId[]);

	const handleDeleteRow = (item: HolidayWithId) => {
		setRow(row.filter(x => x.id !== item.id));
		setScheduleHoliday(row.filter(x => x.id !==item.id));
	};

	useEffect(() => {
		if (holiday){
			setRow(holiday?.map((item: any, index: number) => ({ ...item, id: index })));
		}
	}, [holiday]);

	const getDate = (date: string) => {
		return DateTime.fromISO(date, { zone: "utc" }).toFormat("yyyy-MM-dd'T'HH:mm");
	};

	const handleCreateRows = () => {
		setRow([...row, { startDate: "", endDate: "", id: Math.floor((Math.random() * 1000) + 1), message: { type: Message.type.NONE } }])
	};

	const handleChange = (e: any, index: number) => {
		const [ field ] = e.target.name.split("#");
		const copyRow = row;
		const copy: any = copyRow[index];
		copy[field] = e.target.value;
		copyRow[index] = copy;
		setRow(() => copyRow);

		// Below function is for to write back the state in the parent component
		setScheduleHoliday(() => row);
	}

	return (
		<>
			<Box marginY="space60" display="flex" justifyContent="end" flexDirection="row" columnGap="space60" alignItems="end"></Box>
			<Box>
				<Label htmlFor="table">Schedule Exceptions</Label>
				<Table>
					<THead>
						<Tr>
							<Th>ID</Th>
							<Th>Start Date</Th>
							<Th>End Date</Th>
							<Th textAlign="right">Actions</Th>
						</Tr>
					</THead>
					<TBody>{row?.map((item: HolidayWithId, index: number) => (
						<Tr key={`holidayEntry-${item.id}}`}>
							<Td>{index}</Td>
							<Td>
								<Box width="50%" display="flex" flexDirection="row" columnGap="space50">
									<Box>
										<Label htmlFor="datetimepickerFrom">Start Date</Label>
										<Box style={{
											boxSizing: "border-box",
											display: "flex",
											width: "100%",
											backgroundColor: "rgb(255, 255, 255)",
											boxShadow: "rgb(136 145 170) 0px 0px 0px 1px",
											borderRadius: "4px",
											transition: "box-shadow 100ms ease-in 0s",
											cursor: "text"
										}}>
											<input type="datetime-local" style={{
												boxSizing: "border-box",
												appearance: "none",
												backgroundColor: "transparent",
												border: "none",
												borderRadius: "4px",
												boxShadow: "none",
												color: "inherit",
												cursor: "auto",
												display: "block",
												fontFamily: "inherit",
												fontSize: "0.875rem",
												fontWeight: 500,
												lineHeight: "1.25rem",
												margin: "0px",
												outline: "none",
												padding: "0.45rem 0.75rem",
												resize: "none"
											}} id={`startDate#holiday#${item.id}`} name={`startDate#holiday#${item.id}`}  defaultValue={item.startDate? getDate(item.startDate) : ""} onChange={(e) => handleChange(e, index)} />
										</Box>
									</Box>
								</Box>
							</Td>
							<Td>
								<Box width="50%" display="flex" flexDirection="row" columnGap="space50">
									<Box>
										<Label htmlFor="datetimepickerFrom">End Date</Label>
										<Box style={{
											boxSizing: "border-box",
											display: "flex",
											width: "100%",
											backgroundColor: "rgb(255, 255, 255)",
											boxShadow: "rgb(136 145 170) 0px 0px 0px 1px",
											borderRadius: "4px",
											transition: "box-shadow 100ms ease-in 0s",
											cursor: "text"
										}}>
											<input type="datetime-local" style={{
												boxSizing: "border-box",
												appearance: "none",
												backgroundColor: "transparent",
												border: "none",
												borderRadius: "4px",
												boxShadow: "none",
												color: "inherit",
												cursor: "auto",
												display: "block",
												fontFamily: "inherit",
												fontSize: "0.875rem",
												fontWeight: 500,
												lineHeight: "1.25rem",
												margin: "0px",
												outline: "none",
												padding: "0.45rem 0.75rem",
												resize: "none"
											}} id={`endDate#holiday#${item.id}`} name={`endDate#holiday#${item.id}`}  defaultValue={item.endDate? getDate(item.endDate) : ""} onChange={(e) => handleChange(e, index)} />
										</Box>
										{row[index].startDate > row[index].endDate && <HelpText id={`endDate#holiday#${item.id}`}variant="error"> End Date can&apos;t be before the Start Date </HelpText>}
									</Box>
								</Box>
							</Td>
							<Td textAlign="right">
								<Button onClick={() => {
									handleDeleteRow(item);
								}} variant="destructive">
									<DeleteIcon decorative={false} title="Delete" />
								</Button>
							</Td>
						</Tr>))}</TBody>
				</Table>
				<Box marginY="space60">
					<Button variant="primary" onClick={() => { handleCreateRows(); }}>Add Entry</Button>
				</Box>
			</Box>
		</>
	);
};
