import React, { FC, useEffect, useState } from "react";
import { Table, TBody, Th, THead, Tr, Td } from "@twilio-paste/core/table";
import { Box } from "@twilio-paste/core/box";
import { Input } from "@twilio-paste/core/input";
import { Label } from "@twilio-paste/core/label";
import { Button } from "@twilio-paste/core/button";
import { Separator } from "@twilio-paste/core/separator";
import { useNavigate, useParams } from "react-router-dom";
import { Text } from "@twilio-paste/core/text";
import { SkillSet } from "@ciptex/notified";
import { AlertDialog } from "@twilio-paste/core/alert-dialog";
import { TableSkeletonLoader } from "../TableSkeletonLoader/TableSkeletonLoader";
import { useNotifiedContext } from "../../hooks/useNotifiedContext/useNotifiedContext";
import { useToasterContext } from "../../hooks/useToasterContext/useToasterContext";
import { ChevronDownIcon } from "@twilio-paste/icons/esm/ChevronDownIcon";
import { ChevronUpIcon } from "@twilio-paste/icons/esm/ChevronUpIcon";
import { UnsortedIcon } from "@twilio-paste/icons/esm/UnsortedIcon";
import { SkeletonLoader } from "@twilio-paste/core/skeleton-loader";
import { Flex } from "@twilio-paste/core/flex";

export const EditSkill: FC = () => {
	const { skillSetId } = useParams();
	const [skills, setSkills] = useState<SkillSet>();
	const [newSkill, setNewSkill] = useState<string>();
	const [isOpen, setIsOpen] = useState<boolean>(false);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [loaded, setLoaded] = useState<boolean>(false);
	const handleOpen = () => setIsOpen(true);
	const handleClose = () => setIsOpen(false);
	const navigate = useNavigate();
	const { toaster } = useToasterContext();
	const { getSkill, updateSkill } = useNotifiedContext();
	const [toggleState, setToggleState] = useState<string>("none");

	useEffect(() => {
		if (!skillSetId) {
			navigate("/skills");
			return;
		}
		(async () => {
			try {
				const data: SkillSet = await getSkill(skillSetId);
				setSkills(data);
				setLoaded(true);
			} catch (error) {
				console.error(error);
				toaster.push({
					message: "There has been an error in loading data",
					variant: "error",
					dismissAfter: 4000
				});
			}
		})();
	}, []);

	const sortData = (data: any, sortBy: string, sortOrder: string) => {
		if (data.skills) {
			const sortedItems = data.skills.sort((a: any, b: any) => {
				if (sortBy === "key") {
					if (sortOrder === "asc") {
						setToggleState("asc");
						return b.key.localeCompare(a.key);
					} else {
						setToggleState("desc");
						return a.key.localeCompare(b.key);
					}
				}
			});
			// append sorted items to the original data
			const newData = { ...data, skills: sortedItems };
			return setSkills(() => newData)
		}

	}

	const handleDelete = async (key: string | undefined) => {
		if (!skillSetId || !skills?.name || !skills?.skills || !key) {
			return;
		}

		try {
			const data = await updateSkill(skillSetId, {
				skills: skills.skills.filter((skill: { key?: string | undefined; }) => skill.key !== key)
			} as any);
			setSkills(data);
			toaster.push({
				message: "Skill has been removed",
				variant: "success",
				dismissAfter: 4000
			});
		} catch (error) {
			console.error(error);
			toaster.push({
				message: "Skill could not be removed",
				variant: "error",
				dismissAfter: 4000
			});
		}
		setIsOpen(false);
	};

	const handleChange = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
		setNewSkill(target.value);
	};

	const Update = async (event: any) => {
		event.preventDefault();
		setIsLoading(true);
		if (!skillSetId || !skills?.name || !skills?.skills || !newSkill) {
			return;
		}

		try {
			const data = await updateSkill(skillSetId, {
				skills: [...skills.skills, { key: newSkill }]
			} as any);

			if (data.skillSetId) {
				toaster.push({
					message: "Skill added successfully",
					variant: "success",
					dismissAfter: 4000
				});
				setSkills({ ...skills, skills: [...skills.skills, { key: newSkill }] })
				setIsLoading(false);
			} else {
				toaster.push({
					message: "Skill could not be added",
					variant: "error",
					dismissAfter: 4000
				});
				setIsLoading(false);
			}
		} catch (e) {
			console.error(e);
			toaster.push({
				message: "Skill could not be added",
				variant: "error",
				dismissAfter: 4000
			});
			setIsLoading(false);
		}
	};

	return (
		<>
			<form onSubmit={Update}>
				<Box
					marginY="space60"
					display="flex"
					justifyContent="space-between"
					flexDirection="row"
					columnGap="space60"
					alignItems="end"
				>
					{loaded ? (
						<Box>
							<Text as="h1" fontSize="fontSize40">Skills Set Name: {skills?.name}</Text>
						</Box>
					) : (
						<Box display="flex" justifyContent="start" >
							<SkeletonLoader width="300px" height="36px" />
						</Box>)}
					<Box display="flex" flexDirection="row" columnGap="space60" alignItems="end">
						<Box minWidth="400px">
							<Label htmlFor="key">Name of the Skill</Label>
							<Input id="key" name="key" type="text" placeholder="General" onChange={handleChange} />
						</Box>
						<Box minWidth="100px">
							<Button fullWidth variant="primary" type="submit" loading={isLoading}>
							Add a New Skill
							</Button>
						</Box>
					</Box>

				</Box>
			</form>
			<Separator orientation="horizontal" verticalSpacing="space120" />
			<Box>
				<Table>
					<THead>
						<Tr>
							<Th><Flex vAlignContent="center">
								Skill name{" "}
								<Box marginLeft="space30" display="flex" alignItems="center">
									{toggleState === "desc" ? (
										<Button
											variant="secondary_icon"
											size="reset"
											onClick={() => sortData(skills, "key", "asc")}
										>

											<ChevronUpIcon
												decorative={false}
												size="sizeIcon10"
												title="Ascending"
											/>

										</Button>
									) : (
										<Button
											variant="secondary_icon"
											size="reset"
											onClick={() => sortData(skills, "key", "desc")}
										>
											{toggleState === "none" ? (
												<UnsortedIcon
													decorative={false}
													size="sizeIcon10"
													title="Ascending"
												/> ) : (
												<ChevronDownIcon
													decorative={false}
													size="sizeIcon10"
													title="Descending"
												/>
											)}
										</Button>
									)}
								</Box>
							</Flex></Th>
							<Th textAlign="right">Actions</Th>
						</Tr>
					</THead>
					<TBody>
						{skills?.skills && loaded ? (
							skills.skills.map((skill: { key: string }) => (
								<Tr key={skill.key}>
									<Td>{skill.key}</Td>
									<Td textAlign="right"><Button variant="destructive" size="small" onClick={handleOpen}>
										Delete
									</Button>
									<AlertDialog
										heading="Delete Skill"
										isOpen={isOpen}
										destructive
										onConfirm={() => handleDelete(skill.key)}
										onConfirmLabel="Yes"
										onDismiss={handleClose}
										onDismissLabel="No"
									>Are you sure you want to delete this skill?</AlertDialog>
									</Td>
								</Tr>
							))) : (<TableSkeletonLoader numberOfTr={1} numberOfTd={2} />)}
					</TBody>
				</Table>
			</Box>
		</>
	);
};
