import { BookingStatus } from "@ciptex/notified";
import { Button } from "@twilio-paste/core/dist/button";
import { AcceptIcon } from "@twilio-paste/icons/esm/AcceptIcon";
import { FC, useState } from "react";
import { useAdminContext } from "../../../hooks/useAdminContext/useAdminContext";
import { useNotifiedContext } from "../../../hooks/useNotifiedContext/useNotifiedContext";
import { useToasterContext } from "../../../hooks/useToasterContext/useToasterContext";

export interface ResetBookingStateButtonProps {
    bookingId: string
}

export const ResetBookingStateButton: FC<ResetBookingStateButtonProps> = ({ bookingId }) => {
	const { updateBooking } = useNotifiedContext();
	const { updateBooking: updateBookingState, bookings } = useAdminContext();
	const { toaster } = useToasterContext();
	const [isLoading, setIsLoading ] = useState<boolean>(false);
	const onClick = async () => {
		try {
			setIsLoading(true);
			const bookingState = bookings.find(x => x.bookingId === bookingId);
			if(!bookingState)
			{
				throw new Error("Booking Not Found");
			}

			await updateBooking(bookingId, { status: BookingStatus.ACCEPTED } as any);
			updateBookingState({ ...bookingState, status: BookingStatus.ACCEPTED });
			toaster.push({
				message: "Booking Status Has Been Reset to Accepted",
				variant: "success",
				dismissAfter: 4000
			});
			setIsLoading(false);
		} catch (error: any) {
			console.error(error);
			toaster.push({
				message: "Booking Could Not be Accepted",
				variant: "error",
				dismissAfter: 4000
			});
			setIsLoading(false);
		}
	};
	return (
		<Button variant="secondary" size="small" onClick={onClick} loading={isLoading}>
			<AcceptIcon decorative={true} /> Reset State
		</Button>
	);
};