import { FC } from "react";
import { Box } from "@twilio-paste/core/box";
import { Button } from "@twilio-paste/core/button";
import { HEADERS_HEIGHT } from "../../constants";
import { useNavigate } from "react-router-dom";
import { useAppState } from "../../hooks/useAppState/useAppState";
import { Role } from "@ciptex/notified";

export const SideNav: FC = () => {
	const navigate = useNavigate();
	const { appState } = useAppState();
	return (<Box backgroundColor="colorBackground" display={["none", "none", "flex"]} flexDirection="column" height={`calc(100vh - ${HEADERS_HEIGHT})`}
		minWidth="135px"
		position="sticky"
		top={HEADERS_HEIGHT}
		zIndex="zIndex10">
		<Box marginTop="space70" marginX="space20" overflow="auto" role="navigation" aria-label="Main Nav" display="flex" justifyContent="center"
			paddingY="space40"
			paddingX="space60">
			<Box display="flex" width="100%" flexDirection="column" zIndex="zIndex90" rowGap="space70">
				<Button onClick={() => navigate("bookings")} fullWidth variant="reset">Bookings</Button>
				<Button onClick={() => navigate("calendar")} fullWidth variant="reset">Calendar</Button>
				{appState.role === Role.NOTIFIED_ADMIN && <>
					<Button onClick={() => navigate("schedules")} fullWidth variant="reset">Schedules</Button>
					<Button onClick={() => navigate("hosts")} fullWidth variant="reset">Hosts</Button>
					<Button onClick={() => navigate("skills")} fullWidth variant="reset">Skills</Button>
					<Button onClick={() => navigate("composition")} fullWidth variant="reset">Compositions</Button>
					<Button onClick={() => navigate("insights")} fullWidth variant="reset">Insights</Button>
				</>}
			</Box>
		</Box>
	</Box>);
};
