import { Box } from "@twilio-paste/core/dist/box";
import { Label } from "@twilio-paste/core/dist/label";
import { Select, Option } from "@twilio-paste/core/dist/select";
import { DateTime } from "luxon";
import { ChangeEvent, FC, useEffect, useState } from "react";
import { useAdminContext } from "../../../hooks/useAdminContext/useAdminContext";
import { useAppState } from "../../../hooks/useAppState/useAppState";

export const DateTimeFilter: FC = () => {
	const { appState, appDispatch } = useAppState();
	const { isLoading } = useAdminContext();
	const [showCustomDate, setShowCustomDate] = useState<boolean>(false);
	const [dateTime, setDateTime] = useState<string>("");
	const [startDateFrom, setStartDateFrom] = useState<string>(appState.filters.startDateFrom);
	const [startDateTo, setStartDateTo] = useState<string>(appState.filters.startDateTo);

	const onChangeDateTime = (e: ChangeEvent<HTMLSelectElement>) => {
		setDateTime(e.target.value);
		switch(e.target.value) {
		case "today":
			setShowCustomDate(false);
			appDispatch({
				type: "set-filters",
				filters: {
					...appState.filters,
					dateTime: "today",
					startDateFrom : DateTime.now().startOf("day").toISO(),
					startDateTo: DateTime.now().endOf("day").toISO()
				}
			});
			break;
		case "last30":
			setShowCustomDate(false);
			appDispatch({
				type: "set-filters",
				filters: {
					...appState.filters,
					dateTime: "last30",
					startDateFrom : DateTime.now().minus({ days: 30 }).startOf("day").toISO(),
					startDateTo: DateTime.now().endOf("day").toISO()
				}
			});
			break;
		case "thisMonth":
			setShowCustomDate(false);
			appDispatch({
				type: "set-filters",
				filters: {
					...appState.filters,
					dateTime: "thisMonth",
					startDateFrom : DateTime.now().startOf("month").toISO(),
					startDateTo: DateTime.now().endOf("month").toISO()
				}
			});
			break;
		case "lastMonth":
			setShowCustomDate(false);
			appDispatch({
				type: "set-filters",
				filters: {
					...appState.filters,
					dateTime: "lastMonth",
					startDateFrom : DateTime.now().minus({ months: 1 }).startOf("month").toISO(),
					startDateTo: DateTime.now().minus({ months: 1 }).endOf("month").toISO()
				}
			});
			break;
		case "nextMonth":
			setShowCustomDate(false);
			appDispatch({
				type: "set-filters",
				filters: {
					...appState.filters,
					dateTime: "nextMonth",
					startDateFrom : DateTime.now().plus({ months: 1 }).startOf("month").toISO(),
					startDateTo: DateTime.now().plus({ months: 1 }).endOf("month").toISO()
				}
			});
			break;
		case "next30":
			setShowCustomDate(false);
			appDispatch({
				type: "set-filters",
				filters: {
					...appState.filters,
					dateTime: "next30",
					startDateFrom : DateTime.now().startOf("day").toISO(),
					startDateTo: DateTime.now().plus({ days: 30 }).endOf("day").toISO()
				}
			});
			break;
		case "custom":
			setShowCustomDate(true);
			appDispatch({
				type: "set-filters",
				filters: {
					...appState.filters,
					dateTime: "custom"
				}
			});
			break;
		default:
			setShowCustomDate(false);
			appDispatch({
				type: "set-filters",
				filters: {
					...appState.filters,
					dateTime: "",
					startDateFrom : "",
					startDateTo: ""
				}
			});
			break;
		}
	};

	const onChange = (e: ChangeEvent<HTMLInputElement>) => {
		console.log(e.target.value);
		appDispatch({ type: "set-filters", filters: { ...appState.filters, [e.target.name] : e.target.value } });
	}


	useEffect(() => {
		setStartDateFrom(appState.filters.startDateFrom.replace("Z", ""));
		setStartDateTo(appState.filters.startDateTo.replace("Z", ""));
		setDateTime(appState.filters.dateTime);
		if(appState.filters.dateTime === "custom")
		{
			setShowCustomDate(true);
		}
		else
		{
			setShowCustomDate(false);
		}
	}, [ appState.filters.startDateFrom, appState.filters.startDateTo, appState.filters.dateTime ]);

	return (<>
		<Box width="150px">
			<Label htmlFor="dateTimeFilter">Date &amp; Time Range</Label>
			<Select onChange={onChangeDateTime} name="dateTimeFilter" id="dateTimeFilter" value={dateTime} disabled={isLoading}>
				<Option value="">-</Option>
				<Option value="today">Today</Option>
				<Option value="lastMonth">Last Month</Option>
				<Option value="last30">Last 30 Days</Option>
				<Option value="thisMonth">This Month</Option>
				<Option value="next30">Next 30 Days</Option>
				<Option value="nextMonth">Next Month</Option>
				<Option value="custom">Custom</Option>
			</Select>
		</Box>
		{showCustomDate && (
			<Box display="flex" flexDirection="row" columnGap="space60">
				<Box>
					<Label htmlFor="startDateFrom">From</Label>
					<Box
						style={{
							boxSizing: "border-box",
							display: "flex",
							width: "210px",
							backgroundColor: "rgb(255, 255, 255)",
							boxShadow: "rgb(136 145 170) 0px 0px 0px 1px",
							borderRadius: "4px",
							transition: "box-shadow 100ms ease-in 0s",
							cursor: "text"
						}}
					>
						<input
							onChange={onChange}
							id="startDateFrom"
							name="startDateFrom"
							value={startDateFrom}
							type="datetime-local"
							disabled={isLoading}
							style={{
								boxSizing: "border-box",
								appearance: "none",
								backgroundColor: "transparent",
								border: "none",
								borderRadius: "4px",
								boxShadow: "none",
								color: "inherit",
								cursor: "auto",
								display: "block",
								fontFamily: "inherit",
								fontSize: "0.875rem",
								fontWeight: 500,
								lineHeight: "1.25rem",
								margin: "0px",
								outline: "none",
								padding: "0.45rem 0.75rem",
								resize: "none"
							}}
						/>
					</Box>
				</Box>
				<Box>
					<Label htmlFor="startDateTo">To</Label>
					<Box
						style={{
							boxSizing: "border-box",
							display: "flex",
							width: "210px",
							backgroundColor: "rgb(255, 255, 255)",
							boxShadow: "rgb(136 145 170) 0px 0px 0px 1px",
							borderRadius: "4px",
							transition: "box-shadow 100ms ease-in 0s",
							cursor: "text"
						}}
					>
						<input
							onChange={onChange}
							id="startDateTo"
							name="startDateTo"
							type="datetime-local"
							value={startDateTo}
							disabled={isLoading}
							style={{
								boxSizing: "border-box",
								appearance: "none",
								backgroundColor: "transparent",
								border: "none",
								borderRadius: "4px",
								boxShadow: "none",
								color: "inherit",
								cursor: "auto",
								display: "block",
								fontFamily: "inherit",
								fontSize: "0.875rem",
								fontWeight: 500,
								lineHeight: "1.25rem",
								margin: "0px",
								outline: "none",
								padding: "0.45rem 0.75rem",
								resize: "none"
							}}
						/>
					</Box>
				</Box>
			</Box>
		)}
	</>)
}