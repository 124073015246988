import { DateTime } from "luxon";

export const TidyHostExceptions = (schedule: any) => {
	if (!schedule) {
		return;
	}
	schedule.map((x: { id: any; message: any; startDate: any; endDate: any; }) => {
		delete x.id;
		delete x.message;
		x.startDate = DateTime.fromISO(x.startDate, { zone: "utc" }).toISO();
		x.endDate = DateTime.fromISO(x.endDate, { zone: "utc" }).toISO();
		return x;
	});
};

export const TidyScheduleExceptions = (holiday: any) => {
	if (!holiday) {
		return;
	}
	holiday.map((x: { id: any; message: any; startDate: any; endDate: any; }) => {
		delete x.id;
		x.startDate = DateTime.fromISO(x.startDate, { zone: "utc" }).toISO();
		x.endDate = DateTime.fromISO(x.endDate, { zone: "utc" }).toISO();
		return x;
	});
};

export const TidyScheduleWeekly = (weekly: any) => {
	if (!weekly) {
		return;
	}
	weekly.map((x: { id: any;  startTime: any; endTime: any; }) => {
		delete x.id;
		return x;
	});
};