import { FC } from "react";
import { useNavigate } from "react-router-dom";
import { Tr, Td } from "@twilio-paste/core/table";
import { BookingStatus, Role } from "@ciptex/notified";
import { Button } from "@twilio-paste/button";
import { useAppState } from "../../hooks/useAppState/useAppState";
import { UserIcon } from "@twilio-paste/icons/esm/UserIcon";
import { BookingConferences } from "../../types/ciptex-sdk";
import { BookingColumn } from "../../hooks/useBookingColumns/useBookingColumns";
import { PERMENANT_BOOKING_COLUMNS } from "../../constants";
import { RenderRow } from "./RenderRow";
import { AcceptBookingButton } from "../Buttons/AcceptBookingButton/AcceptBookingButton";
import { ResetBookingStateButton } from "../Buttons/ResetBookingStateButton/ResetBookingStateButton";

interface TableBooking {
	booking: BookingConferences;
}

export const BookingRow: FC<TableBooking> = ({ booking }) => {
	const navigate = useNavigate();
	const { appState } = useAppState();
	const permenantFields = PERMENANT_BOOKING_COLUMNS
		.filter(x => !((x.fieldPath === "conference" && x.name === "conferenceName" || x.fieldPath === "conference" && x.name === "startDate")));

	const optionalFields = appState.columns
		.filter(x => !(x.fieldPath === "conference" && x.name === "conferenceId"));

	return (
		<Tr>
			{[...permenantFields, ...optionalFields]
				.map((column: BookingColumn, index: number) => {
					if (column.value) {
						return <RenderRow key={`${booking.bookingId}-${column.fieldPath}.${column.name}`} column={column} index={index} data={booking} />;
					} else {
						return null;
					}
				})}
			<Td textAlign="right">
				{booking.status === BookingStatus.PENDING &&
					appState.role === Role.NOTIFIED_ADMIN && (
					<AcceptBookingButton bookingId={booking.bookingId} />
				)}
				{booking.status === BookingStatus.COMPLETED &&
					appState.role === Role.NOTIFIED_ADMIN && (
					<ResetBookingStateButton bookingId={booking.bookingId} />
				)}
				{booking.status === BookingStatus.ACCEPTED &&
					appState.role === Role.NOTIFIED_ADMIN && (
					<Button variant="secondary" size="small" onClick={() => { navigate(`/bookings/${booking.bookingId}`); }}>
						<UserIcon decorative={true} />	Assign Hosts
					</Button>
				)}
			</Td>
		</Tr>
	);
};
