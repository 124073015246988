import { BOOKING_COLUMN_COOKIE_NAME } from "../../constants";
import { useAppState } from "../useAppState/useAppState";

export interface BookingColumn {
    name: string;
    title: string;
    value: boolean;
	fieldPath: string;
	format?: string[];
}

export const useBookingColumns = () => {
	const { appDispatch } = useAppState();

	const setColumns = (columns: BookingColumn[]) => {
		console.log("setColumns", columns);
		appDispatch({ type: "set-booking-columns", columns });

		const expiry = new Date();
		expiry.setTime(expiry.getTime() + (365 * 24 * 60 * 60 * 1000));
		const expires = "expires=" + expiry.toUTCString();
		document.cookie = BOOKING_COLUMN_COOKIE_NAME + "=" + JSON.stringify(columns) + ";" + expires + ";path=/";
	}

	return { setColumns }
}