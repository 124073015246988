import { FC, useState } from "react";
import { Td } from "@twilio-paste/core/table";
import { Button } from "@twilio-paste/button";
import { BookingItem, BookingStatus, ConferenceItem } from "@ciptex/notified";
import { Box } from "@twilio-paste/core/box";
import { CONFERENCE_URL, PERMENANT_BOOKING_COLUMNS } from "../../constants";
import { useAppState } from "../../hooks/useAppState/useAppState";
import { BookingColumn } from "../../hooks/useBookingColumns/useBookingColumns";
import { RenderRow } from "./RenderRow";
import { useConferenceControlContext } from "../../hooks/useConferenceControlContext/useConferenceControlContext";
import { CiptexRoomStatus } from "@ciptex/conference-control";
import { useToasterContext } from "../../hooks/useToasterContext/useToasterContext";

interface TableConference {
	conference: ConferenceItem;
	booking: BookingItem;
}

export const ConferenceRow: FC<TableConference> = ({ conference, booking }) => {
	const { appState } = useAppState();
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const { checkRoomStatus } = useConferenceControlContext();
	const { toaster } = useToasterContext();
	const parmanentFields = PERMENANT_BOOKING_COLUMNS
		.filter(x => !(x.fieldPath === "booking.event" && x.name === "name" || x.fieldPath === "booking.event" && x.name === "startDate"))

	const optionalFields = appState.columns
		.filter(x => !(x.fieldPath === "booking" && x.name === "bookingId"));

	const openInsights = async (confSid: string) => {
		setIsLoading(true);
		try {
			const content: CiptexRoomStatus = await checkRoomStatus(confSid);
			window.open(`https://console.twilio.com/us1/monitor/insights/video/video-rooms?frameUrl=/console/video/logs/rooms/${content.roomId}`, "_blank");
			setIsLoading(false);
		} catch (e) {
			console.error(e);
			toaster.push({
				message: "The room does not exist. Make sure you are using the Main Room to access the room insights.",
				variant: "error",
				dismissAfter: 4000
			});
			setIsLoading(false);
		}
	}

	const isOld = (date: string) => {
		const today = new Date();
		const eventDate = new Date(date);
		const diff = today.getTime() - eventDate.getTime();
		const diffDays = Math.ceil(diff / (1000 * 3600 * 24));
		return diffDays !<= 8 && diffDays > 0;
	}

	return (
		<tr style={{ border: "solid 1px rgb(225, 227, 234)", backgroundColor: "#fafafa" }} key={`tr-${conference.conferenceId}`}>
			{[...parmanentFields, ...optionalFields]
				.map((column: BookingColumn, index) => {
					if (column.value) {
						return <RenderRow key={`${conference.conferenceId}-${column.fieldPath}.${column.name}`} column={column} index={index} data={conference} />;
					} else {
						return null
					}
				}
				)}
			<Td textAlign="right">
				<Box display={["none", "none", "none"]} width="100%" justifyContent="flex-end">
					{booking.status === BookingStatus.ACCEPTED && <Button variant="primary" as="a" size="small" href={`${CONFERENCE_URL}/host/${booking.bookingId}/${conference.conferenceId}?t=${appState.token}&h=${appState.hostId}`}>Join Stream</Button>}
				</Box>
				<Box display={["flex", "flex", "flex"]} width="100%" justifyContent="flex-end">
					{booking.status === BookingStatus.ACCEPTED && <Button variant="primary" as="a" size="small" href={`${CONFERENCE_URL}/host/${booking.bookingId}/${conference.conferenceId}?t=${appState.token}&h=${appState.hostId}`}>Join</Button>}
				</Box>
				<Box display={"flex"} width="100%" justifyContent="flex-end">

					{booking.status === BookingStatus.COMPLETED && (isOld(conference.startDate)) && <Button variant="primary" as="button" loading={isLoading} onClick={() => openInsights(conference.conferenceId)} size="small" >Open Insights</Button>}
				</Box>
			</Td>
		</tr>
	);
};
