import { FC, useState } from "react";
import { Box } from "@twilio-paste/core/box";
import { Button } from "@twilio-paste/core/button";
import { Combobox } from "@twilio-paste/core/combobox";
import { Input } from "@twilio-paste/core/input";
import { Label } from "@twilio-paste/core/label";
import { Schedule, ScheduleDetail } from "@ciptex/schedule-sdk";
import { ScheduleExceptionMaker } from "../ScheduleExceptionMaker/ScheduleExceptionMaker";
import { ScheduleMaker } from "../ScheduleMaker/ScheduleMaker";
import { Separator } from "@twilio-paste/core/separator";
import { useNavigate } from "react-router-dom";
import { useScheduleContext } from "../../hooks/useScheduleContext/useScheduleContext";
import { useToasterContext } from "../../hooks/useToasterContext/useToasterContext";
import { TIMEZONES } from "../../constants";
import { TidyScheduleExceptions, TidyScheduleWeekly } from "../../functions/clean-schedule";

export const AddSchedule: FC = (props) => {
	const { createSchedule } = useScheduleContext();
	const [holiday, setHoliday] = useState<any>();
	const navigate = useNavigate();
	const [schedule, setSchedule] = useState<ScheduleDetail>();
	const [submissionLoading, setSubmissionLoading] = useState<boolean>(false);
	const [timezone, setTimezone] = useState(TIMEZONES);
	const { toaster } = useToasterContext();
	const [weekly, setWeekly] = useState<any>();

	const handleChange = ({ target }: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
		setSchedule({
			...schedule,
			[target.name]: target.value
		} as ScheduleDetail);
	}

	const handleSubmit = async (e: any) => {
		e.preventDefault();
		if (!schedule) {
			return;
		}
		setSubmissionLoading(true);
		TidyScheduleExceptions(holiday);
		TidyScheduleWeekly(weekly);
		schedule.holiday = holiday;
		schedule.weekly = weekly;
		try {
			const content: Schedule = await createSchedule(schedule);
			if (content.scheduleId) {
				setSubmissionLoading(false);
				toaster.push({
					message: "Schedule added successfully",
					variant: "success",
					dismissAfter: 4000
				});
				navigate("/schedules", { replace: true });
			} else {
				toaster.push({
					message: "Schedule could not be added",
					variant: "error",
					dismissAfter: 4000
				});
				setSubmissionLoading(false);
			}
		} catch (error: any) {
			console.error(error);
			toaster.push({
				message: "Schedule could not be added",
				variant: "error",
				dismissAfter: 4000
			});
			setSubmissionLoading(false);
		}
	}

	return (
		<>
			<Box
				marginY="space60"
				display="flex"
				justifyContent="start"
				flexDirection="row"
				columnGap="space60"
				alignItems="end"
			>
				<Box>
					<Label htmlFor="new-schedule">Schedule Name</Label>
					<Input
						id="name"
						name="name"
						type="text"
						placeholder="General"
						onChange={handleChange}
					/>
				</Box>
				<Box>
					<Combobox
						autocomplete
						items={timezone}
						labelText="Schedule Timezones"
						name="timezone"
						placeholder="Europe/London"
						onInputValueChange={({ inputValue }) => {
							if (inputValue !== undefined) {
								const tz: string[] = TIMEZONES.filter(item => item.toLowerCase().startsWith(inputValue.toLowerCase()));
								if ( tz.length === 1) {
									setSchedule({
										...schedule,
										timezone: tz.toString()
									} as ScheduleDetail);
								}
								setTimezone(TIMEZONES.filter(item => item.toLowerCase().startsWith(inputValue.toLowerCase())));
							}
						}}
					/>
				</Box>
			</Box>
			<Separator orientation="horizontal" verticalSpacing="space120" />
			<ScheduleMaker {...props} setScheduleWeekly={setWeekly}/>
			<Separator orientation="horizontal" verticalSpacing="space120" />
			<ScheduleExceptionMaker {...props} setScheduleHoliday={setHoliday} />
			<Separator orientation="horizontal" verticalSpacing="space120" />
			<Box
				marginY="space60"
				display="flex"
				justifyContent="end"
				flexDirection="row"
				columnGap="space60"
				alignItems="end"
			>
				<Button variant="primary" onClick={(e) => handleSubmit(e)} loading={submissionLoading}>Save</Button>
			</Box>
		</>
	);
};