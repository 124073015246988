import { useContext } from "react";
import { NotifiedContext } from "../../components/NotifiedProvider/NotifiedProvider";
import { NotifiedContextType } from "../../types/ciptex-sdk";

export const useNotifiedContext = (): NotifiedContextType => {
	const context = useContext(NotifiedContext);
	if (!context) {
		throw new Error("useNotifiedContext must be used within a NotifiedProvider");
	}
	return context;
}