import { createContext, FC, useCallback, useState } from "react";
import { ReactElementProps } from "../../interface";
import { ScheduleContextType } from "../../types/ciptex-sdk";
import { ScheduleClient, Schedule, ScheduleDetail } from "@ciptex/schedule-sdk";

export const ScheduleContext = createContext<ScheduleContextType>(null!);

export const ScheduleProvider: FC<ReactElementProps> = ({ children }: ReactElementProps) => {
	const [scheduleClient, setScheduleClient] = useState<ScheduleClient>();
	const [providerReady, setProviderReady] = useState<boolean>(false);

	const connect = useCallback((token: string) => {
		try {
			const client = new ScheduleClient({ TOKEN: token });
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			window.scheduleClient = client;
			setScheduleClient(client);
			setProviderReady(true);
		} catch (error: any) {
			console.error(error);
		}
	}, []);

	const listSchedule = useCallback(async (pageSize?: number, lastKey?: string): Promise<Schedule[]> => {
		const schedules = await scheduleClient?.schedule.list(pageSize, lastKey);
		if (schedules) {
			return schedules;
		}
		else {
			throw new Error("No Shedules");
		}
	}, [scheduleClient]);

	const getSchedule = useCallback(async (scheduleId: string): Promise<Schedule> => {
		const schedule = await scheduleClient?.schedule.getSchedule(scheduleId);
		if (schedule) {
			return schedule;
		}
		else {
			throw new Error("No Schedule");
		}
	}, [scheduleClient]);

	const removeSchedule = useCallback(async (scheduleId: string): Promise<void> => {
		await scheduleClient?.schedule.remove(scheduleId);
	}, [scheduleClient]);

	const createSchedule = useCallback(async (scheduleObject: ScheduleDetail): Promise<Schedule> => {
		const newSchedule = await scheduleClient?.schedule.create(scheduleObject);
		if (newSchedule) {
			return newSchedule;
		}
		else {
			throw new Error("No Schedule Created");
		}
	}, [scheduleClient]);

	const updateSchedule = useCallback(async (scheduleId: string, scheduleObject: ScheduleDetail): Promise<Schedule> => {
		const updatedSchedule = await scheduleClient?.schedule.update(scheduleId, scheduleObject);
		if (updatedSchedule) {
			return updatedSchedule;
		}
		else {
			throw new Error("No Schedule Updated");
		}
	}, [scheduleClient]);


	return <ScheduleContext.Provider value={{ providerReady,  connect, listSchedule, getSchedule, removeSchedule, createSchedule, updateSchedule }}>{children}</ScheduleContext.Provider>;
}