import { useContext } from "react";
import { AdminContext } from "../../components/AdminProvider/AdminProvider";
import { AdminContextType } from "../../types/ciptex-sdk";

export const useAdminContext = (): AdminContextType => {
	const context = useContext(AdminContext);
	if (!context) {
		throw new Error("useAdminContext must be used within a AdminProvider");
	}
	return context;
}