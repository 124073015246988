import { createContext, FC, useCallback, useState } from "react";
import { VideoContextType } from "../../types/ciptex-sdk";
import { Composition, CompositionDetail, CompositionSid, RoomSid, VideoClient } from "@ciptex/video-sdk";
import { ReactElementProps } from "../../interface";

export const VideoContext = createContext<VideoContextType>(null!);

export const VideoProvider: FC<ReactElementProps> = ({ children }: ReactElementProps) => {
	const [ videoClient, setVideoClient] = useState<VideoClient>();
	const [ providerReady, setProviderReady] = useState<boolean>(false);

	const connect = useCallback((token: string) => {
		try {
			const client = new VideoClient({ token });
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			setVideoClient(client);
			setProviderReady(true);
		} catch (error: any) {
			console.error(error);
		}
	}, []);

	const createComposition = useCallback(async (roomSid: RoomSid): Promise<CompositionDetail> => {
		const composition = await videoClient?.composition.create({ "roomSid": roomSid }, {});
		if (composition) {
			return composition;
		}
		else {
			throw new Error("No Room Found to Create Composition");
		}
	}, [videoClient]);

	const listCompositions = useCallback(async (roomSid: RoomSid): Promise<Composition[]> => {
		const composition = await videoClient?.composition.list({ "roomSid": roomSid });
		if (composition) {
			return composition;
		}
		else {
			throw new Error("No Room Found to Create Composition");
		}
	}, [videoClient]);

	const getComposition = useCallback(async (compSid: CompositionSid): Promise<CompositionDetail> => {
		const composition = await videoClient?.composition.fetch({ "compositionSid": compSid });
		if (composition) {
			return composition;
		}
		else {
			throw new Error("No Composition Found");
		}
	}, [videoClient]);

	const downloadComposition = useCallback(async (compSid: CompositionSid): Promise<CompositionDetail> => {
		const composition = await videoClient?.compositionmedia.compositionMedia({ "compositionSid": compSid });
		if (composition) {
			return composition;
		}
		else {
			throw new Error("No Composition Found");
		}
	}, [videoClient]);


	return <VideoContext.Provider value={{ providerReady, connect, createComposition, getComposition, downloadComposition, listCompositions }}>{children}</VideoContext.Provider>;
}