import { FC, useState } from "react";
import { Box } from "@twilio-paste/core/box";
import { Button } from "@twilio-paste/button";
import { Input } from "@twilio-paste/core/input";
import { Label } from "@twilio-paste/core/label";
import { useConferenceControlContext } from "../../hooks/useConferenceControlContext/useConferenceControlContext";
import { useToasterContext } from "../../hooks/useToasterContext/useToasterContext";
import { CiptexRoomStatus } from "@ciptex/conference-control";
import { Badge, CodeBlock, Heading, Separator, Table, TBody, Td, Th, THead, Tr } from "@twilio-paste/core";
import { useVideoContext } from "../../hooks/useVideoContext/useVideoContext";
import { Composition } from "@ciptex/video-sdk";
// import { InformationIcon } from "@twilio-paste/icons/esm/InformationIcon";

export const VideoComposition: FC = () => {
	const [submissionLoading1, setSubmissionLoading1] = useState<boolean>(false);
	const [submissionLoading2, setSubmissionLoading2] = useState<boolean>(false);
	const [submissionLoading3, setSubmissionLoading3] = useState<boolean>(false);
	const [confSid, setConfSid] = useState<string>("");
	const [compSid, setCompSid] = useState<string>("");
	const [compSidVO, setCompSidVO] = useState<string>("");
	const [compList, setCompList] = useState<Composition[]>([]);
	const [downloadUrl, setDownloadUrl] = useState<string>("");
	const { checkRoomStatus } = useConferenceControlContext();
	const { toaster } = useToasterContext();
	const { createComposition, downloadComposition, listCompositions } = useVideoContext();

	const onChange = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
		if(target.name === "conferenceSid") {
			setConfSid(target.value);
		} else if (target.name){
			setCompSid(target.value);
		} else {
			console.log("test")
		}
	};

	const roomSid = async (confSid: string) => {
		const content: CiptexRoomStatus = await checkRoomStatus(confSid);
		if (content.status === "completed") {
			return content.roomId;
		} else {
			toaster.push({
				message: "Room Check Failed, Conference is not completed",
				variant: "error",
				dismissAfter: 4000
			});
			return null;
		}
	};

	const GetComposition = async (event: any) => {
		event.preventDefault();
		setSubmissionLoading1(true);
		try {
			const content: any = await roomSid(confSid);
			if(content) {
				console.log("ROOM ID", content);
				const data: any = await createComposition(content);
				setCompSidVO(data.sid);
				navigator.clipboard.writeText(data.sid);
				toaster.push({
					message: "The composition has been request please allow 15 min before accessing the file, the Composition SID has been copied to your clipboard.",
					variant: "success",
					dismissAfter: 6000
				});
				setSubmissionLoading1(false);
			} else {
				toaster.push({
					message: "Could not request the composition.",
					variant: "error",
					dismissAfter: 6000
				});
				setCompSidVO("");
				setSubmissionLoading1(false);
			}
		} catch (e) {
			console.error(e);
			toaster.push({
				message: "Invalid Conference SID",
				variant: "error",
				dismissAfter: 6000
			});
			setSubmissionLoading1(false);
		}
	};

	const DownloadComposition = async (event: any) => {
		event.preventDefault();
		setSubmissionLoading2(true);
		try {
			const data: any = await downloadComposition(compSid);
			console.log("DATA", data);
			setDownloadUrl(data.redirect_to);
			navigator.clipboard.writeText(data.redirect_to);
			toaster.push({
				message: "The URL has been copied to your clipboard",
				variant: "success",
				dismissAfter: 6000
			});
			setSubmissionLoading2(false);
		} catch (e) {
			console.error(e);
			toaster.push({
				message: "Invalid Composition SID",
				variant: "error",
				dismissAfter: 6000
			});
			setSubmissionLoading2(false);
			setDownloadUrl("");
		}
	}

	const GetAllComposition = async (event: any) => {
		event.preventDefault();
		setSubmissionLoading3(true);
		try {
			const content: any = await roomSid(confSid);
			if(content) {
				console.log("ROOM ID", content);
				const data: Composition[] = await listCompositions(content);
				setCompList(data);
				toaster.push({
					message: "All Compositions have been retrieved",
					variant: "success",
					dismissAfter: 6000
				});
				setSubmissionLoading3(false);
			} else {
				toaster.push({
					message: "Could not request the compositions.",
					variant: "error",
					dismissAfter: 6000
				});
				setCompSidVO("");
				setSubmissionLoading3(false);
			}
		} catch (e) {
			console.error(e);
			toaster.push({
				message: "Invalid Conference SID",
				variant: "error",
				dismissAfter: 6000
			});
			setSubmissionLoading3(false);
		}
	};

	return (
		<Box>
			<Box display="flex" alignItems="center">
				<Heading as="h2" variant="heading20" marginBottom="space0">
Request Composition
				</Heading>
				{/* <Tooltip baseId="tooltip-example" text="Use the Conference SID to request a composition for a completed conference."><InformationIcon decorative={false} title="Open Tooltip" display="block" /></Tooltip> */}
			</Box>
			<form onSubmit={GetComposition}>
				<Box
					marginY="space60"
					display="flex"
					justifyContent="start"
					flexDirection="row"
					columnGap="space60"
					alignItems="end"
				>
					<Box width="size70">
						<Label required htmlFor="conferenceSid">Conference SID</Label>
						<Input
							id="conferenceSid"
							name="conferenceSid"
							type="text"
							placeholder="CIxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx"
							onChange={onChange}
							required
						/>
					</Box>
					<Button type="submit" variant="primary" loading={submissionLoading1}>
					Request Composition
					</Button>
				</Box>
			</form>{compSidVO ?
				<Box width="size70">
					<Label htmlFor="compsid">Composition SID</Label>
					<CodeBlock variant="single-line" language='javascript' code={compSidVO}/>
				</Box>: null}
			<Separator orientation="horizontal" verticalSpacing="space120"/>
			<Heading as="h2" variant="heading20" marginBottom="space0">
Download Composition
			</Heading>
			{/* <Tooltip baseId="tooltip-example1" text="Use the Composition SID to download the composition."><InformationIcon decorative={false} title="Open Tooltip" display="block" /></Tooltip> */}
			<form onSubmit={DownloadComposition}>
				<Box
					marginY="space60"
					display="flex"
					justifyContent="start"
					flexDirection="row"
					columnGap="space60"
					alignItems="end"
				>
					<Box width="size70">
						<Label required htmlFor="compositionSid">Composition SID</Label>
						<Input
							id="compositionSid"
							name="compositionSid"
							type="text"
							placeholder="CJxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx"
							onChange={onChange}
							required
						/>
					</Box>
					<Button type="submit" variant="primary" loading={submissionLoading2}>
					Download Composition
					</Button>
				</Box>
				{downloadUrl ?
					<Box width="size70" >
						<Label htmlFor="downloadUrl">Download URL</Label>
						<CodeBlock variant="single-line" language='javascript' code={downloadUrl}/>
						<Box marginTop="space50"><Button as="a" variant="secondary" href={downloadUrl}>Open the link</Button></Box>
					</Box>: null}
			</form>
			<Separator orientation="horizontal" verticalSpacing="space120"/>
			<Heading as="h2" variant="heading20" marginBottom="space0">
Get All Compositions
			</Heading>
			{/* <Tooltip baseId="tooltip-example3" text="Use the Conference SID to get all the compositions for a completed conference. You may have requested multiple compositions for a conference, but all the files will be the same."><InformationIcon decorative={false} title="Open Tooltip" display="block" /></Tooltip> */}
			<form onSubmit={GetAllComposition}>
				<Box
					marginY="space60"
					display="flex"
					justifyContent="start"
					flexDirection="row"
					columnGap="space60"
					alignItems="end"
				>
					<Box width="size70">
						<Label required htmlFor="conferenceSid">Conference SID</Label>
						<Input
							id="conferenceSid"
							name="conferenceSid"
							type="text"
							placeholder="CIxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx"
							onChange={onChange}
							required
						/>
					</Box>
					<Button type="submit" variant="primary" loading={submissionLoading3}>
					Get All Compositions
					</Button>
				</Box>
				{compList.length > 0 ?
					<>

						<Box  width="size70">
							<Table>
								<THead>
									<Tr>
										<Th>Composition SID</Th>
										<Th>Status</Th>
									</Tr>
								</THead>
								<TBody>
									{compList.map((comp, index) => (
										<Tr key={index}>
											<Td>{comp.compositionSid}</Td>
											<Td> <Badge as="span" variant="neutral">{comp.status}</Badge></Td>
										</Tr>
									))}
								</TBody>
							</Table>
						</Box>
					</> : null}


			</form>
		</Box>
	);
};

