import { createContext, FC, useCallback, useState } from "react";
import { ConferenceControlContextType } from "../../types/ciptex-sdk";
import { ConferenceControlClient, CiptexRoomStatus } from "@ciptex/conference-control";
import { ReactElementProps } from "../../interface";

export const ConferenceControlContext = createContext<ConferenceControlContextType>(null!);

export const ConferenceControlProvider: FC<ReactElementProps> = ({ children }: ReactElementProps) => {
	const [conferenceClient, setConferenceClient] = useState<ConferenceControlClient>();
	const [ providerReady, setProviderReady] = useState<boolean>(false);

	const connect = useCallback((token: string) => {
		try {
			const client = new ConferenceControlClient({ token });
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			setConferenceClient(client);
			setProviderReady(true);
		} catch (error: any) {
			console.error(error);
		}
	}, []);

	const checkRoomStatus = useCallback(async (conferenceSid: string): Promise<CiptexRoomStatus> => {
		const roomStatus = await conferenceClient?.room.fetch(conferenceSid);
		if (roomStatus) {
			return roomStatus;
		}
		else {
			throw new Error("No Room Found");
		}
	}, [conferenceClient]);


	return <ConferenceControlContext.Provider value={{ providerReady, connect, checkRoomStatus }}>{children}</ConferenceControlContext.Provider>;
}