import { FC, useEffect, useState } from "react";
import { Table, TBody, Th, THead, Tr, Td } from "@twilio-paste/core/table";
import { Box } from "@twilio-paste/core/box";
import { Label } from "@twilio-paste/core/label";
import { Button } from "@twilio-paste/core/button";
import { DeleteIcon } from "@twilio-paste/icons/esm/DeleteIcon";
import { TimePicker } from "@twilio-paste/core/time-picker";
import { Select, Option } from "@twilio-paste/core/select";
import { TimeEntry } from "@ciptex/schedule-sdk";
import { HelpText } from "@twilio-paste/core";

interface TimeEntryWithId extends TimeEntry {
	id: number
}

interface ScheduleMakerTable {
	weekly?: any,
	setScheduleWeekly?: any,
}

export const ScheduleMaker: FC<ScheduleMakerTable> = ({ setScheduleWeekly, weekly, ...props }) => {
	const [row, setRow] = useState([] as TimeEntryWithId[]);

	const handleDeleteRow = (item: TimeEntryWithId) => {
		setRow(row.filter(x => x.id !== item.id));
		setScheduleWeekly(row.filter(x => x.id !==item.id));
	};

	useEffect(() => {
		if (weekly){
			setRow(weekly?.map((item: any, index: number) => ({ ...item, id: index })));
		}
	}, [weekly]);

	const handleCreateRows = () => {
		setRow([...row, { startTime: "", endTime: "", day: "" as TimeEntry.day, id: Math.floor((Math.random() * 1000) + 1) }]);
	};

	const handleChange = (e: any, index: number) => {
		const [ field ] = e.target.name.split("#");
		const copyRow = row;
		const copy: any = copyRow[index];
		copy[field] = e.target.value;
		copyRow[index] = copy;
		setRow(() => copyRow);

		// Below function is for to write back the state in the parent component
		setScheduleWeekly(() => row);
	}

	return (
		<>
			<Box marginY="space60" display="flex" justifyContent="end" flexDirection="row" columnGap="space60" alignItems="end"></Box>
			<Box>
				<Label htmlFor="table">Weekly Recurring Schedule</Label>
				<Table>
					<THead>
						<Tr>
							<Th>ID</Th>
							<Th>Day</Th>
							<Th>Start Time</Th>
							<Th>End Time</Th>
							<Th textAlign="right">Actions</Th>
						</Tr>
					</THead>
					<TBody>{row?.map((item: TimeEntryWithId, index: number) => (
						<Tr key={`timeEntry-${item.id}}`}>
							<Td>{index}</Td>
							<Td>
								<Box width="50%">
									<Label htmlFor={`day#weekly#${index}`}>Day</Label>
									<Select defaultValue={item.day} id={`day#weekly#${index}`}  name={`day#weekly#${index}`} onChange={(e) => handleChange(e, index)}>
										<Option value="">Select a Day</Option>
										<Option value="mon">Monday</Option>
										<Option value="tue">Tuesday</Option>
										<Option value="wed">Wednesday</Option>
										<Option value="thu">Thursday</Option>
										<Option value="fri">Friday</Option>
										<Option value="sat">Saturday</Option>
										<Option value="sun">Sunday</Option>
									</Select>
								</Box>
							</Td>
							<Td>
								<Box width="50%">
									<Label htmlFor={`startTime#weekly#${index}`}>Start time</Label>
									<TimePicker id={`startTime#weekly#${index}`} name={`startTime#weekly#${index}`} {...props} defaultValue={item.startTime? item.startTime : ""} onBlur={(e) => handleChange(e, index)} onChange={(e) => handleChange(e, index)}/>
								</Box>
							</Td>
							<Td>
								<Box width="50%">
									<Label htmlFor={`endTime#weekly#${index}`}>End time</Label>
									<TimePicker id={`endTime#weekly#${index}`}  name={`endTime#weekly#${index}`} {...props} defaultValue={item.endTime? item.endTime : ""} onBlur={(e) => handleChange(e, index)}  onChange={(e) => handleChange(e, index)} />
									{row[index].startTime > row[index].endTime && <HelpText id={`endTime#weekly#${index}`} variant="error"> End time can&apos;t be before the Start time </HelpText>}
								</Box>
							</Td>
							<Td textAlign="right">
								<Button
									onClick={() => {
										handleDeleteRow(item);
									}}
									variant="destructive"
								>
									<DeleteIcon decorative={false} title="Delete" />
								</Button>
							</Td>
						</Tr>
					))}</TBody>
				</Table>
				<Box marginY="space60">
					<Button variant="primary" onClick={handleCreateRows}>Add Entry</Button>
				</Box>
			</Box>
		</>
	);
};
