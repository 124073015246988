import { BookingStatus, ConferenceItem, ConferenceType } from "@ciptex/notified";
import { Badge } from "@twilio-paste/core/dist/badge";
import { Box } from "@twilio-paste/core/dist/box";
import { Td, Th } from "@twilio-paste/core/dist/table";
import { Text } from "@twilio-paste/core/dist/text";
import { useUID } from "@twilio-paste/core/dist/uid-library";
import { Truncate } from "@twilio-paste/truncate";
import { CheckboxCheckIcon } from "@twilio-paste/icons/esm/CheckboxCheckIcon";
import { CloseIcon } from "@twilio-paste/icons/esm/CloseIcon";
import { FC } from "react";
import { ROOM_TYPE_BADGE_COLOURS, STATUS_BADGE_COLOURS } from "../../constants";
import { CapitaliseFirstLetter, secondsToHumanReadable } from "../../functions/general";
import { RenderColumn } from "../../functions/render-column";
import { BookingColumn } from "../../hooks/useBookingColumns/useBookingColumns";
import { useHostList } from "../../hooks/useHostList/useHostList";
import { BookingConferences } from "../../types/ciptex-sdk";

interface RenderRowProps {
	data: BookingConferences | ConferenceItem;
	column: BookingColumn
    index: number
}

export const RenderRow: FC<RenderRowProps> = ({ data, column, index }) => {
	const { getHostName } = useHostList();
	const uid = useUID();

	const Render = ({ name, format, fieldPath }: BookingColumn, index: number) => {
		const value = RenderColumn(name, fieldPath, data, format);

		if(value === undefined)
		{
			return <Td></Td>;
		}

		if (format?.includes("statusBadge")) {
			return (
				<Td textAlign="center" key={uid}>
					<Box display="flex" justifyContent="center">
						<Badge as="span" variant={STATUS_BADGE_COLOURS[value as BookingStatus]}>{CapitaliseFirstLetter(value as string)}</Badge>
					</Box>
				</Td>);
		}

		if (name === "duration") {
			return (
				<Td textAlign="center" key={uid}>
					<Box display="flex" justifyContent="center">
						{secondsToHumanReadable(value)}
					</Box>
				</Td>);
		}

		if(format?.includes("roomTypeBadge")) {
			return (
				<Td textAlign="center" key={uid}>
					<Box display="flex" justifyContent="center">
						<Badge as="span" variant={ROOM_TYPE_BADGE_COLOURS[value as ConferenceType]}>{CapitaliseFirstLetter(value as string)}</Badge>
					</Box>
				</Td>);
		}

		if (format?.includes("bold")) {
			if(format.includes("truncate"))
			{
				return (
					<Th scope="row" key={uid}>
						<Truncate title={value?.toString() || "-"}>
							<Text as="p">{value || "-"}</Text>
						</Truncate>
					</Th>
				);
			}
			else
			{
				return (<Th scope="row" key={index}>{value || "-"}</Th>);
			}
		}

		if(typeof value === "boolean")
		{
			if(value)
			{
				return <Td textAlign="center">
					<Box display="flex" justifyContent="center">
						<CheckboxCheckIcon decorative={false} title={`${column.name} is true`} />
					</Box>
				</Td>
			}
			else
			{
				return <Td textAlign="center">
					<Box display="flex" justifyContent="center">
						<CloseIcon decorative={false} title={`${column.name} is false`} />
					</Box>
				</Td>
			}
		}

		if (Array.isArray(value)) {
			return <Td textAlign="center">
				<Box display="flex" justifyContent="center" flexDirection="row" rowGap="space30" columnGap="space30" flexWrap="wrap">
					{value.map((x) => (
						<Box display="flex" justifyContent="center" key={`${uid}-${x}`}>
							<Badge as="span" variant="info">{format?.includes("hosts") ?  getHostName(x) : x}</Badge>
						</Box>))}
				</Box>
			</Td>
		}

		return (<Td textAlign="center" key={index}>{value || "-"}</Td>);
	};

	return Render(column, index);
}